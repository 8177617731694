<template>
	<div class="formMain">

		<div class="formBodyPositioning">
			<Header />
			<div class="formWidth">
				<div class="formMainModule">
					<div class="formInfomationTitle">
						基本信息
					</div>

					<div class="relevantQuestionMain" id="xqBox">
						<div class="relevantQuestion">

							<!--  -->


							<div class="highlightRemarks">
								<p>注： 红色 <span class="reRed">*</span>为必填项</p>
								<div class="tooltipState" v-if="autoDodge">
									<i>您于{{toDate}}提交了{{toType}}，本次填报部分字段数据被自动调入！</i>
									<img class="cuowu" src="../../assets/img/filledTuring/错误.png" alt="" @click="close">
								</div>
							</div>

							<!--  -->
							<li>
								<label for="companyName">企业名称<span class="reRed">*</span></label>
								<div class="baseRight empty">
									<input type="text" id="companyName" class="required" data-value='false'
										v-model="basicInformation.companyName" @blur="searchMatch($event)" @focus="focus($event)"
										:disabled="traceStatus" autocomplete="off">
								</div>
							</li>
							<li>
								<label for="companyShort">简称<span class="reRed">*</span></label>
								<div class="baseRight empty">
									<input type="text" id="companyShort" class="required" data-value='false'
										v-model="basicInformation.companyShort" :disabled="traceStatus" @blur="blur($event)"
										@focus="focus($event)" autocomplete="off">
								</div>
							</li>
							<li>
								<label for="email">邮箱<span class="reRed">*</span></label>
								<div class="baseRight empty">
									<input type="text" id="email" class="required" data-value='false' v-model="basicInformation.email"
										:disabled="traceStatus" @blur="blur($event)" @focus="focus($event)" autocomplete="off">
								</div>
							</li>
							<li>
								<label for="time">成立日期<span class="reRed">*</span></label>
								<div class="baseRight empty">
									<Date-picker size="small" value-format="yyyy-MM-dd" type="date" confirm :editable="false"
										placeholder="选择成立日期" :options="forbidDate" id="time" @on-change="timeDate"
										v-model="basicInformation.establishDate" :disabled="traceStatus">
									</Date-picker>
								</div>
							</li>
							<li>
								<label for="legalRepresentative">法定代表人</label>
								<div class="baseRight">
									<input type="text" id="legalPeople" maxlength="20" v-model="basicInformation.legalPeople"
										:disabled="traceStatus" @blur="blur($event)" @focus="focus($event)" autocomplete="off">
								</div>
							</li>
							<li>
								<label>创始人学历</label>
								<div class="baseRight founderDegree">
									<Select @on-change="founderDegree" v-model="basicInformation.founderEdu" :disabled="traceStatus">
										<Option v-for="(item, i) in education" :value="item.label" :key="i">{{ item.label }}</Option>
									</Select>
								</div>
							</li>
							<li>
								<label for="registeredCapital">注册资本
								</label>
								<div class="baseRight">
									<input type="number" autocomplete="off" id="regCapital" v-model="basicInformation.regCapital"
										@blur="displayThousandths($event,basicInformation.regCapital,'regCapital')" @focus="focus($event)"
										:disabled="traceStatus">
									<div :class="['showInput',traceStatus?'ash':'']" @click="inputFocus($event)">
										{{this.formatCurrency.formateNum(basicInformation.regCapital,2)}}
									</div>
									<span class="measure">万元</span>
								</div>
							</li>

							<li class="location">
								<label for="locationEnterprise">企业所在地</label>
								<div class="baseRight">
									<Select v-model="basicInformation.area" :disabled="traceStatus">
										<Option v-for="(item, i) in location" :value="item.value" :key="i">{{ item.value }}</Option>
									</Select>
								</div>
							</li>
							<li>
								<label for="address">详细地址</label>
								<div class="baseRight">
									<input type="text" id="address" v-model="basicInformation.address" :disabled="traceStatus"
										@blur="blur($event)" @focus="focus($event)" autocomplete="off">
								</div>
							</li>
							<li>
								<label for="socialCode">统一社会信用代码<span class="reRed">*</span></label>
								<div class="baseRight empty">
									<input type="text" id="creditCode" class="required" data-value='false' maxlength="18"
										v-model="basicInformation.creditCode" :disabled="traceStatus" @blur="blur($event)"
										@focus="focus($event)" autocomplete="off">
								</div>
							</li>

							<li class="promoteMarket">
								<label>产品是否已推广到市场<span class="reRed">*</span></label>
								<div class="baseRight promoteMarketOption empty">
									<Select v-model="basicInformation.extendMarketFlag" :disabled="traceStatus" @on-change="market">
										<Option value="是">是</Option>
										<Option value="否">否</Option>
									</Select>
								</div>
							</li>
							<li>
								<label for="financingQuota">拟融资额度</label>
								<div class="baseRight">
									<input type="number" id="finance" v-model="basicInformation.finance"
										@blur="displayThousandths($event, basicInformation.finance, 'finance')" @focus="focus($event)"
										:disabled="traceStatus" autocomplete="off">
									<div :class="['showInput', traceStatus ? 'ash' : '']" @click="inputFocus($event)">
										{{ this.formatCurrency.formateNum(basicInformation.finance, 2) }}
									</div>
									<span class="measure">万元</span>
								</div>
							</li>
							<li style="width:100%">
								<label>公司主营业务概述<span class="reRed">*</span></label>
								<div class="baseRight bussinessDescBox empty" style="border: none;">
									<textarea name="" id="bussinessDesc" :class="['required', 'normal', traceStatus ? 'ash' : '']"
										cols="20" rows="4" maxlength="100" placeholder="最多输入100个字" v-model="basicInformation.bussinessDesc"
										:disabled="traceStatus" @blur="blur($event)" @focus="focus($event)"></textarea>
								</div>
							</li>
							<li>
							</li>



							<li>
								<label>所属行业<span class="reRed">*</span></label>
								<div class="baseRight empty">
									<div class="firstIndustry">
										<Select v-model="basicInformation.industryName" @on-change="industryChange" :disabled="traceStatus">
											<Option v-for="(item, i) in firstIndustry" :value="item.name" :key="i">
												{{ item.name }}
											</Option>
										</Select>
									</div>
								</div>
							</li>
							<li>
								<label style="visibility: hidden;">所属行业<span class="reRed">*</span></label>
								<div class="baseRight empty">
									<Select v-model="basicInformation.industryChildName" @on-change="secondIndustryChange"
										:disabled="traceStatus">
										<Option v-for="(item, i) in secondIndustry" :value="item.name" :key="i">
											{{ item.name }}
										</Option>
									</Select>
								</div>
							</li>


							<li>
								<label for="shareProportion">计划出让股权比例</label>
								<div class="baseRight">
									<input type="number" autocomplete="off" id="ratio" v-model="basicInformation.ratio"
										@blur="((ev) => { percentage(ev, basicInformation, 'ratio') })" @focus="focus($event)"
										:disabled="traceStatus">
									<span class="measure">%</span>
								</div>
							</li>

							<li>
								<label for="enterpriseValue">企业价值（自评）<span class="reRed">*</span></label>
								<div class="baseRight empty">
									<input type="number" autocomplete="off" id="selfValue" class="required" data-value='false'
										v-model="basicInformation.selfValue"
										@blur="displayThousandths($event, basicInformation.selfValue, 'selfValue')" @focus="focus($event)"
										:disabled="traceStatus">
									<div style="display: none;" :class="['showInput', traceStatus ? 'ash' : '']"
										@click="inputFocus($event)">
										{{ this.formatCurrency.formateNum(basicInformation.selfValue, 2) }}
									</div>
									<span class="measure">万元</span>
								</div>
							</li>
							<li class="companyRevenue">
								<label for="companyRevenue">
									<input type="text" class="companyRevenueDate" :value="forecastYear" readonly="readonly"
										data-type="date">公司营业收入(预测)
									<span class="reRed">*</span>
									<div class="desc-container">
										<i class="explain">?</i>
										<div class="desc-main">
											<span></span>
											<p>公司未来十二个月的营业收入</p>
										</div>
									</div>

								</label>
								<div class="baseRight empty">

									<input type="number" id="income" class="required" data-value='false' v-model="basicInformation.income"
										@blur="displayThousandths($event, basicInformation.income, 'income')" @focus="focus($event)"
										:disabled="traceStatus" autocomplete="off">
									<div :class="['showInput', traceStatus ? 'ash' : '']" @click="inputFocus($event)">
										{{ this.formatCurrency.formateNum(basicInformation.income, 2) }}
									</div>
									<span class="measure">万元</span>
								</div>
							</li>

							<li>
								<label for="companyNetAssets">
									<input type="text" class="companyRevenueDate" :value="forecastYear" readonly="readonly"
										data-type="date">公司净资产(预测)
									<span class="reRed">*</span>
									<div class="desc-container">

										<i class="explain">?</i>
										<div class="desc-main">
											<span></span>
											<p>公司未来十二个月的净资产</p>
										</div>
									</div>
								</label>
								<div class="baseRight empty">
									<div class="companyRevenueRight ">
										<input type="number" id="netAsset" class="required" data-value='false'
											v-model="basicInformation.netAsset"
											@blur="displayThousandths($event, basicInformation.netAsset, 'netAsset')" @focus="focus($event)"
											:disabled="traceStatus" autocomplete="off">
										<div :class="['showInput', traceStatus ? 'ash' : '']" @click="inputFocus($event)">
											{{ this.formatCurrency.formateNum(basicInformation.netAsset, 2) }}
										</div>
										<span class="measure">万元</span>
									</div>
								</div>
							</li>


							<li>
								<label for="netProfit">
									<input type="text" class="companyRevenueDate" :value="forecastYear" readonly="readonly"
										data-type="date">公司净利润（预测）
									<span class="reRed">*</span>
									<div class="desc-container">

										<i class="explain">?</i>
										<div class="desc-main">
											<span></span>
											<p>公司未来十二个月的净利润</p>
										</div>
									</div>
								</label>
								<div class="baseRight empty">
									<div class="companyRevenueRight">
										<input type="number" id="netProfit" class="required" data-value='false'
											v-model="basicInformation.netProfit"
											@blur="displayThousandths($event, basicInformation.netProfit, 'netProfit')" @focus="focus($event)"
											:disabled="traceStatus" autocomplete="off">
										<div :class="['showInput', traceStatus ? 'ash' : '']" @click="inputFocus($event)">
											{{ this.formatCurrency.formateNum(basicInformation.netProfit, 2) }}
										</div>
										<span class="measure">万元</span>
									</div>
								</div>
							</li>
							<li>
								<label for="oldNetProfit">
									<input type="text" class="companyRevenueDate" :value="forecastYear" readonly="readonly"
										data-type="date">公司息税折旧摊销前利润(预测)
									<span class="reRed">*</span>
									<div class="desc-container">
										<i class="explain">?</i>
										<div class="desc-main">
											<span></span>
											<p>公司未来十二个月息税<br>折旧摊销前利润</p>
										</div>
									</div>
								</label>
								<div class="baseRight empty">
									<div class="companyRevenueRight">
										<input type="number" id="amortizeProfit" class="required" data-value='false'
											v-model="basicInformation.amortizeProfit"
											@blur="displayThousandths($event, basicInformation.amortizeProfit, 'amortizeProfit')"
											@focus="focus($event)" :disabled="traceStatus" autocomplete="off">
										<div :class="['showInput', traceStatus ? 'ash' : '']" @click="inputFocus($event)">
											{{ this.formatCurrency.formateNum(basicInformation.amortizeProfit, 2) }}
										</div>
										<span class="measure">万元</span>
									</div>
								</div>
							</li>

							<li>
								<label for="firstHolderRatio">第一股东持股比例<span class="reRed">*</span></label>
								<div class="baseRight empty">
									<input type="number" id="firstHolderRatio" autocomplete="off" class="required holder"
										data-value='false' v-model="basicInformation.firstHolderRatio"
										@blur="((ev) => { percentage(ev, basicInformation, 'firstHolderRatio') })" @focus="focus($event)"
										:disabled="traceStatus">
									<span class="measure">%</span>
								</div>
							</li>
							<li>
								<label for="secHolderRatio">第二股东持股比例</label>
								<div class="baseRight">
									<input type="number" autocomplete="off" id="secHolderRatio" class="relation holder"
										v-model="basicInformation.secHolderRatio"
										@blur="((ev) => { percentage(ev, basicInformation, 'secHolderRatio') })" @focus="focus($event)"
										:disabled="traceStatus">
									<span class="measure">%</span>
								</div>
							</li>
							<li>
								<label for="thirdHolderRatio">第三股东持股比例</label>
								<div class="baseRight">
									<input type="number" autocomplete="off" id="thirdHolderRatio" class="relation holder"
										v-model="basicInformation.thirdHolderRatio"
										@blur="((ev) => { percentage(ev, basicInformation, 'thirdHolderRatio') })" @focus="focus($event)"
										:disabled="traceStatus">
									<span class="measure">%</span>
								</div>
							</li>
							<li>
								<label for="fourHolderRatio">第四股东持股比例</label>
								<div class="baseRight">
									<input type="number" autocomplete="off" id="fourHolderRatio" class="relation holder"
										v-model="basicInformation.fourHolderRatio"
										@blur="((ev) => { percentage(ev, basicInformation, 'fourHolderRatio') })" @focus="focus($event)"
										:disabled="traceStatus">
									<span class="measure">%</span>
								</div>
							</li>
							<li>
								<label for="otherHolderRatio">其他股东持股比例</label>
								<div class="baseRight">
									<input type="number" autocomplete="off" id="otherHolderRatio" readonly="readonly" class="readonColor"
										v-model="basicInformation.otherHolderRatio">
									<span class="measure">%</span>
								</div>
							</li>
							<li style="visibility: hidden;">
								<label for="otherHolderRatio">其他股东持股比例</label>
								<div class="baseRight">
									<input type="number" autocomplete="off" id="otherHolderRatio" readonly="readonly" class="readonColor"
										v-model="basicInformation.otherHolderRatio">
									<span class="measure">%</span>
								</div>
							</li>




						</div>
						<div class="saveBox">
							<span class="closePage" id="saveButton" @click="closeStep">关闭</span>
							<button v-if="!traceStatus" class="save" id="saveButton" data-isClick="false" @click="save"
								v-preventReClick>保 存</button>

							<!-- <span  class="save" id="closeButton" @click="nextStep">关闭</span> -->
						</div>
						<!-- <Modal v-model="myAlert" class-name="vertical-center-modal" :mask-closable="false">
				<img class='warning' src="../../assets/img/remind-01@2x.png">
				<span>{{markedWords}}</span>
				<div slot="footer" class="ivu-sure" @click="submitDialog">确定</div>
			</Modal> -->

						<!-- <div @click="openMask">打开弹窗</div> -->
						<!-- {{popButonType}} -->
						<dialogBar v-model="myAlert" :type="popButonType" :title='markedWords' content=""
							@confirmJump="clickConfirmJump()" @cacheTrueMask="clickCacheTrueMaskJump()"
							@cacheMask="clickCacheMaskJump()" @closeMaskInfoJump="closeMaskInfoJump()"></dialogBar>


					</div>
				</div>
			</div>
			<Footer />
		</div>
	</div>

</template>

<script>
	// 头部组件引入
	import Header from '../../common/header/header.vue';
	// 尾部组件引入
	import Footer from '../../common/footer/footer.vue';
	import '../../assets/css/common.css';


	import navigation from './navigation.vue';
	import tsconfig from '../../assets/js/tsconfig.json';
	import {
		mapState,
		mapMutations
	} from 'vuex';
	import qs from 'qs'
	import axios from 'axios';
	// import '../../assets/libs/js/jquery-1.11.1.min.js';
	import $ from 'jquery'
	import dialogBar from './popue.vue'


	// import navigation from './navigation.vue';
	export default {
		data() {
			return {
				sendVal: false,
				navText: '估值报告',
				autoDodge: false,
				toDate: 'XXX年X月X日',
				toType: 'XXXX',
				markedWords: '', //填写错误的提示语
				popButonType: 'notEmpty', //弹出框展示对应的状态
				myAlert: false,
				education: [{ //创始人学历
					label: '大专及以下'
				}, {
					label: '本科'
				}, {
					label: '硕士'
				}, {
					label: '博士'
				}],
				location: [], //企业所在地
				firstIndustry: [],
				secondIndustry: [],
				forecastYear: '',
				diagnoseBaseDate: [], //诊断基准日
				basicInformation: {
					companyName: '',
					companyShort: '', // 公司简称
					email: '', // 邮件
					establishDate: '', //成立时间
					legalPeople: '', //法定代表人
					founderEdu: '', //创始人学历
					regCapital: '', //注册资本
					area: '', //企业所在地
					address: '', // 详细地址
					// regDepart: '', // 登记机关
					creditCode: '', // 统一社会信用代码
					bussinessDesc: '', // 公司主营业务概述
					extendMarketFlag: '', //产品是否已推广到市场
					industry: '', // 一级行业标识码
					industryChild: '', //二级行业标识码
					industryName: '', //一级行业名称
					industryChildName: '', //二级行业名称
					finance: '', // 拟融资额度
					ratio: '', // 计划出让股权比例
					selfValue: '', //企业价值（自评）
					income: '', // 公司营业收入(预测)
					netAsset: '', // 公司净资产(预测)
					netProfit: '', //公司净利润（预测）
					amortizeProfit: '', // 公司息税折旧摊销前利润(预测)
					// valuationPurpose: '', // 估值目的
					// writerEnterpriseRelative: '', // 填写人与受评企业关系
					firstHolderRatio: '', // 第一股东持股比例
					secHolderRatio: '', // 第二股东持股比例
					thirdHolderRatio: '', // 第三股东持股比例
					fourHolderRatio: '', // 第四股东持股比例
					otherHolderRatio: '', // 其他股东持股比例
					diagnoseBaseDate: '',
					// position: '4',
					valuationReportId: '',
					id: '',
					position: '0'
				},
				reportMatchId: '',
				traceStatus: false, //价值追踪input 禁用状态，默认不禁用
				versions: '2',
				diagnoseShow: false, //新版诊断要显示的数据 默认不展示
				projectName: '',
				disabledDate: '',
				forbidDate: {
					disabledDate(date) {
						const disabledDate = date.getFullYear()
						return (
							disabledDate > new Date().getFullYear()
						)
					}
				},
				empty: '',
				scrollTop: 0
			}
		},
		components: {
			Header,
			Footer,
			navigation,
			dialogBar
		},
		mounted() {
			// wangdi   修改判断
			if (this.$route.query.type == 12) {
				this.projectName = this.domain.turingProject;
			}

			this.pageModify('false');
			this.location = tsconfig.base.location;
			this.oneIndustry();


			// wangdi  修改判断
			// console.log(this.$route.query.reportId)
			if (this.$route.query.reportId) {
				// console.log("111")
				this.judgeYear()

			} else {
				var nowYear = new Date();
				this.forecastYear = nowYear.getFullYear() + '年';
			}

			this.getBaseInfo()
			// wangdi  修改判断
			if (this.$route.query.type == 12) {
				this.diagnoseShow = true;
			}

		},
		methods: {

			...mapMutations(['pageModify']),
			timeDate: function(val) {
				this.pageModify('true');
				this.basicInformation.establishDate = val;
				document.querySelectorAll('.empty')[3].querySelector('.ivu-date-picker').style.borderColor = '#00E4FF'
				//调用缓存共用方法
				if (!this.$route.query.reportId || (this.$route.query.reportId && !this.contentData) || (this.$route.query
						.reportId && this.contentData && this.isCacheValue == '0')) {
					this.method.caching("baseInfo", JSON.stringify(this.basicInformation), this.$route.query.type, this.$route
						.query.stage, this)
				}
			},
			founderDegree: function(val) {
				this.pageModify('true');
				//调用缓存共用方法
				if (!this.$route.query.reportId || (this.$route.query.reportId && !this.contentData) || (this.$route.query
						.reportId && this.contentData && this.isCacheValue == '0')) {
					this.method.caching("baseInfo", JSON.stringify(this.basicInformation), this.$route.query.type, this.$route
						.query.stage, this)
				}
				// console.log(val)
			},
			percentage: function(e, val, key) {
				this.pageModify('true');
				e.currentTarget.style.border = "1px solid #00E4FF";
				this.fixed(val, key)
				//调用缓存共用方法
				if (!this.$route.query.reportId || (this.$route.query.reportId && !this.contentData) || (this.$route.query
						.reportId && this.contentData && this.isCacheValue == '0')) {
					this.method.caching("baseInfo", JSON.stringify(this.basicInformation), this.$route.query.type, this.$route
						.query.stage, this)
				}
			},
			focus: function(e) {

				e.currentTarget.style.border = "1px solid #64A6FF";

			},
			blur: function(e) {
				this.pageModify('true');

				e.currentTarget.style.border = "1px solid #00E4FF";
				if (this.method.filterSensitiveWords(e.target, this.basicInformation)) {
					e.currentTarget.style.borderColor = "red";
					this.myAlert = true;
					this.popButonType = 'notEmpty';
					this.markedWords = '您输入的内容存在敏感词';
				} else {
					e.currentTarget.style.borderColor = "#00E4FF";
				}
				//调用缓存共用方法
				if (!this.$route.query.reportId || (this.$route.query.reportId && !this.contentData) || (this.$route.query
						.reportId && this.contentData && this.isCacheValue == '0')) {
					this.method.caching("baseInfo", JSON.stringify(this.basicInformation), this.$route.query.type, this.$route
						.query.stage, this)
				}

			},
			fixed: function(data, key) {
				if (data[key] > 100) {
					this.myAlert = true;
					this.popButonType = 'notEmpty';
					this.markedWords = '请输入不大于100的数字';
					data[key] = '';
				} else {
					data[key] = Number(data[key]).toFixed(2)
				}

				if (key == 'firstHolderRatio') {
					this.basicInformation.otherHolderRatio = 100 - Number(this.basicInformation.firstHolderRatio) -
						Number(this.basicInformation.secHolderRatio) - Number(this.basicInformation.thirdHolderRatio) -
						Number(this.basicInformation.fourHolderRatio);
				} else if (key == 'secHolderRatio') {
					if (this.basicInformation.secHolderRatio > this.basicInformation.firstHolderRatio || this
						.basicInformation.secHolderRatio < 0 || (Number(this.basicInformation.secHolderRatio) + Number(
							this.basicInformation.firstHolderRatio)) > 100) {
						this.myAlert = true;
						this.popButonType = 'notEmpty';
						this.markedWords = '请输入正确的持股比例';
						data['secHolderRatio'] = '';
					} else {
						this.basicInformation.otherHolderRatio = 100 - Number(this.basicInformation.firstHolderRatio) -
							Number(this.basicInformation.secHolderRatio) - Number(this.basicInformation
								.thirdHolderRatio) - Number(this.basicInformation.fourHolderRatio);
					}
				} else if (key == 'thirdHolderRatio') {
					if (this.basicInformation.thirdHolderRatio > this.basicInformation.secHolderRatio || this
						.basicInformation.thirdHolderRatio < 0 || (Number(this.basicInformation.thirdHolderRatio) +
							Number(this.basicInformation.secHolderRatio) + Number(this.basicInformation
								.firstHolderRatio)) > 100) {
						this.myAlert = true;
						this.popButonType = 'notEmpty';
						this.markedWords = '请输入正确的持股比例';
						data['thirdHolderRatio'] = '';
					} else {
						this.basicInformation.otherHolderRatio = 100 - Number(this.basicInformation.firstHolderRatio) -
							Number(this.basicInformation.secHolderRatio) - Number(this.basicInformation
								.thirdHolderRatio) - Number(this.basicInformation.fourHolderRatio);
					}
				} else if (key == 'fourHolderRatio') {
					if (Number(this.basicInformation.fourHolderRatio) > Number(this.basicInformation
							.thirdHolderRatio) || this
						.basicInformation.fourHolderRatio < 0 || (Number(this.basicInformation.fourHolderRatio) +
							Number(this.basicInformation.thirdHolderRatio) + Number(this.basicInformation
								.secHolderRatio) + Number(this.basicInformation.firstHolderRatio)) > 100) {
						this.myAlert = true;
						this.popButonType = 'notEmpty';
						this.markedWords = '请输入正确的持股比例';
						data['fourHolderRatio'] = '';
					} else {
						this.basicInformation.otherHolderRatio = 100 - Number(this.basicInformation.firstHolderRatio) -
							Number(this.basicInformation.secHolderRatio) - Number(this.basicInformation
								.thirdHolderRatio) - Number(this.basicInformation.fourHolderRatio);
					}
				} else if (key == 'ratio') {
					if (this.basicInformation.finance && this.basicInformation.ratio) {
						this.basicInformation.selfValue = (Number(this.basicInformation.finance) / (Number(this.basicInformation
							.ratio) / 100)).toFixed(2)
					}
				}
				//调用缓存共用方法
				if (!this.$route.query.reportId || (this.$route.query.reportId && !this.contentData) || (this.$route.query
						.reportId && this.contentData && this.isCacheValue == '0')) {
					this.method.caching("baseInfo", JSON.stringify(this.basicInformation), this.$route.query.type, this.$route
						.query.stage, this)
				}

			},
			submitDialog: function() {
				this.myAlert = false;
			},
			judgeYear: function() {
				// console.log("12345678")
				this.$post(this.projectName + 'valuationReport/qryValuationCurrentYear', qs.stringify({
						reportId: this.$route.query.reportId
					}))
					.then((res) => {
						this.forecastYear = res.data.content.reportCreateTime + '年';
					});
			},
			searchMatch: function(e, dataKey) { //部分字段自动调入功能
				this.pageModify('true');
				// console.log(this.method.filterSensitiveWords(e.target))
				e.currentTarget.style.border = "1px solid #00E4FF";
				if (this.method.filterSensitiveWords(e.target, this.basicInformation)) {
					e.currentTarget.style.borderColor = "red";
					this.myAlert = true;
					this.popButonType = 'notEmpty';
					this.markedWords = '您输入的内容存在敏感词';
				} else {
					e.currentTarget.style.borderColor = "#00E4FF";
				}
				// this.$route.query.type != 5  wangdi   删除判断  没有5的状态
				if (this.basicInformation.companyName != '' && !this.$route.query
					.reportId) {
					this.$post(this.projectName + 'valuationReport/getBaseInfoByCompanyName', qs.stringify({
							name: this.basicInformation.companyName,
							stage: this.$route.query.stage,
							type: this.$route.query.type
						}))
						.then((res) => {
							var defaultDataKey = Object.keys(this.basicInformation)
							if (res.data.code == 200 && res.data.content != null) {
								
								// dataSource ：0官网   1用友
								// if (res.data.content.dataSource == '0') {
								// 	this.autoDodge = true;
								// } else {
								// 	this.autoDodge = false;
								// }

								if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) {
									$('.baseContent').css('margin-top', '100px');
								}

								var content = res.data.content;
								var dataKey = Object.keys(content); //获取对象的 key
								this.reportMatchId = content.valuationReportId;
								if (content.createTime) {
									this.toDate = content.createTime.split("-")[0] + "年" + content.createTime
										.split(
											"-")[1] + "月" + content.createTime.split("-")[2] + "日";
								}

								if (content.reportType == 1 && content.stage == 2) {
									this.toType = "成长期快速估值";
								} else if (content.reportType == 1 && content.stage == 3) {
									this.toType = "成熟期快速估值";
								} else if (content.reportType == 2 && content.stage == 2) {
									this.toType = "成长期专业估值";
								} else if (content.reportType == 2 && content.stage == 3) {
									this.toType = "成熟期专业估值";
								} else if (content.reportType == 7 && content.stage == 1) {
									this.toType = "初创期价值诊断";
								} else if (content.reportType == 7 && content.stage == 2) {
									this.toType = "成长期价值诊断";
								} else if (content.reportType == 7 && content.stage == 3) {
									this.toType = "成熟期价值诊断";
								} else if (content.reportType == 8 && content.stage == 1) {
									this.toType = "初创期价值演示";
								} else if (content.reportType == 8 && content.stage == 2) {
									this.toType = "成长期价值演示";
								} else if (content.reportType == 8 && content.stage == 3) {
									this.toType = "成熟期价值演示";
								} else if (content.reportType == 7 && content.stage == 7) {
									this.toType = "企业价值诊断";
								} else if (content.reportType == 2 && content.stage == 7) {
									this.toType = "企业价值量化";
								} else if (content.stage == 1) {
									this.toType = "初创期估值";
								} else if (content.reportType == 12 && content.stage == 2) {
									// 新增图灵估值  wangdi
									this.toType = "图灵估值";
								}

								this.assignment(dataKey, defaultDataKey, content, 'searchMatch')
							}
							// else {
							// 	defaultDataKey.forEach((item, idx) => {
							// 		// if (item != 'companyName') {
							// 			this.basicInformation[item] = '';
							// 		// }
							// 	})
							// }
						});
				}

				//调用缓存共用方法
				if (!this.$route.query.reportId || (this.$route.query.reportId && !this.contentData) || (this.$route.query
						.reportId && this.contentData && this.isCacheValue == '0')) {
					this.method.caching("baseInfo", JSON.stringify(this.basicInformation), this.$route.query.type, this.$route
						.query.stage, this)
				}

			},
			save: function() {
				var emailReg = /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/; //邮箱
				var creditCodeReg = /^[0-9A-Z]{18}$/; //统一社会信用代码
				var url = '';

				if (!this.basicInformation.companyName) {
					this.myAlert = true;
					this.popButonType = 'notEmpty-info';
					this.markedWords = '请输入企业名称';
					this.empty = 0;
					return false;
				}
				if (!this.basicInformation.companyShort) {
					this.myAlert = true;
					this.popButonType = 'notEmpty-info';
					this.markedWords = '请输入简称';
					this.empty = 1;
					return false;
				}
				if (!this.basicInformation.email) {
					this.myAlert = true;
					this.popButonType = 'notEmpty-info';
					this.markedWords = '请输入邮箱';
					this.empty = 2;
					return false;
				} else {
					if (!emailReg.test(this.basicInformation.email)) {
						this.myAlert = true;
						this.popButonType = 'notEmpty-info';
						this.markedWords = '请输入正确的邮箱';
						this.empty = 2;
						return false;
					}
				}
				if (!this.basicInformation.establishDate) {
					this.myAlert = true;
					this.popButonType = 'notEmpty-info';
					this.markedWords = '请选择成立日期';
					this.empty = 3;
					return false;
				}
				if (!this.basicInformation.creditCode) {
					this.myAlert = true;
					this.popButonType = 'notEmpty-info';
					this.markedWords = '请输入统一社会信用代码';
					this.empty = 4;
					return false;
				} else {
					if (!creditCodeReg.test(this.basicInformation.creditCode)) {
						this.myAlert = true;
						this.popButonType = 'notEmpty-info';
						this.markedWords = '信用代码输入有误';
						this.empty = 4;
						return false;
					}
				}

				if (this.diagnoseShow && !this.basicInformation.extendMarketFlag) {
					this.myAlert = true;
					this.popButonType = 'notEmpty-info';
					this.markedWords = '请选择产品是否已推广到市场';
					this.empty = 5;
					return false;
				}
				if (!this.basicInformation.bussinessDesc) {
					this.myAlert = true;
					this.popButonType = 'notEmpty-info';
					this.markedWords = '请输入公司主营业务概述';
					this.empty = 6;
					return false;
				}
				if (!this.basicInformation.industryName) {
					this.myAlert = true;
					this.popButonType = 'notEmpty-info';
					this.markedWords = '请选择所属一级行业';
					this.empty = 7;
					return false;
				}
				if (!this.basicInformation.industryChildName) {
					this.myAlert = true;
					this.popButonType = 'notEmpty-info';
					this.markedWords = '请选择所属二级行业';
					this.empty = 8;
					return false;
				}
				if (this.diagnoseShow && !this.basicInformation.selfValue) {
					this.myAlert = true;
					this.popButonType = 'notEmpty-info';
					this.markedWords = '请输入企业价值（自评）';
					this.empty = 9;
					return false;
				}

				if (this.diagnoseShow && !this.basicInformation.income) {
					this.myAlert = true;
					this.popButonType = 'notEmpty-info';
					this.markedWords = '请输入公司营业收入(预测)';
					this.empty = 10;
					return false;
				}
				if (this.diagnoseShow && !this.basicInformation.netAsset) {
					this.myAlert = true;
					this.popButonType = 'notEmpty-info';
					this.markedWords = '请输入公司净资产(预测)';
					this.empty = 11;
					return false;
				}
				if (this.diagnoseShow && !this.basicInformation.netProfit) {
					this.myAlert = true;
					this.popButonType = 'notEmpty-info';
					this.markedWords = '请输入公司净利润(预测)';
					this.empty = 12;
					return false;
				}
				if (this.diagnoseShow && !this.basicInformation.amortizeProfit) {
					this.myAlert = true;
					this.popButonType = 'notEmpty-info';
					this.markedWords = '请输入公司息税折旧摊销前利润(预测)';
					this.empty = 13;
					return false;
				}
				if (!this.basicInformation.firstHolderRatio || this.basicInformation.firstHolderRatio == 0) {
					this.myAlert = true;
					this.popButonType = 'notEmpty-info';
					this.markedWords = '请输入第一股东持股比例';
					this.empty = 14;
					return false;
				}


				// 修改判断  wangdi
				url = this.projectName + 'baseInfo/save';


				// console.log(this.basicInformation)


				this.$post(url, qs.stringify({
						// reportId: this.$route.query.reportId ? this.$route.query.reportId : '',
						original: this.reportMatchId,
						baseInfo: JSON.stringify(this.basicInformation),
						stage: this.$route.query.stage,
						reportType: this.$route.query.type,
						versions: this.versions
					}))
					.then((res) => {
						// console.log(res.data)
						this.$router.push({
							path: '/home',
							query: {
								stage: this.$route.query.stage,
								type: this.$route.query.type,
								reportId: res.data.content.valuationReportId
							}
						})

					});
			},
			closeStep: function() {
				this.myAlert = true;
				this.popButonType = 'closeData';
				this.markedWords = '当前数据尚未保存，是否确定离开？';
				// this.$router.push({
				// 	path: '/softInformation',
				// 	query: {
				// 		stage: this.$route.query.stage,
				// 		type: this.$route.query.type,
				// 		reportId: this.$route.query.reportId,
				// 		traceReportId: this.$route.query.traceReportId,
				// 		year: this.$route.query.year
				// 	}
				// })
			},
			getBaseInfo: function(close) {
				this.$post(this.projectName + 'baseInfo/getBaseInfo', qs.stringify({
						reportId: this.$route.query.reportId ? this.$route.query.reportId : '',
						versions: this.versions,
						stage: this.$route.query.stage,
						reportType: this.$route.query.type,
					}))
					.then((res) => {
						this.basicInformation.valuationReportId = this.$route.query.reportId;
						this.contentData = res.data.content

						if (res.data.content) {
							// 调用 关闭 清空数据------------
							this.isCacheValue = res.data.content.isCache
							if (this.isCacheValue == '0' && close == '0') {
								this.method.clearCaching(this.$route.query.type, this.$route.query.stage, this)
							}
							if (res.data.content.isCache == "0" && !sessionStorage.isHistoryCache) {
								this.myAlert = true;
								this.popButonType = 'cacheType';
								this.markedWords = '您上次填写过估值报告，是否载入缓存信息？';
							} else {
								var content = res.data.content;
								var dataKey = Object.keys(content); //获取对象的 key
								var defaultDataKey = Object.keys(this.basicInformation)
								this.basicInformation.valuationReportId = this.$route.query.reportId;
								console.log(this.$route.query.reportId)
								this.basicInformation.id = content.id;
								this.assignment(dataKey, defaultDataKey, content, 'getBaseInfo')
							}

						}
					});
			},

			assignment: function(param, data, content, describe) {
				//param:参数key的数组   data：主要数据key的数组  content：接口返回的数据      describe：表示是填充调入的数据还是回显的数据
				data.forEach((elem, index) => {
					param.forEach((item, idx) => {
						if (elem == item) {
							if (item == 'regCapital' || item == 'finance' ||
								item == 'income' || item == 'netAsset' || item ==
								'netProfit' || item == 'amortizeProfit') {
								if (document.getElementById(item)) {
									document.getElementById(item).nextSibling.style
										.display = 'block';
								}
							}
							if (item == 'ratio' || item == 'firstHolderRatio' ||
								item == 'secHolderRatio' || item ==
								'thirdHolderRatio' || item == 'fourHolderRatio' ||
								item == 'otherHolderRatio') {
								this.basicInformation[item] = content[item] ?
									Number(content[item]).toFixed(2) : '';
							} else if (item == 'extendMarketFlag') {
								if (content[item] == '0') {
									this.basicInformation[item] = '否';
								} else {
									this.basicInformation[item] = '是';
								}
							} else if (item == 'diagnoseBaseDate') {
								for (var i = 0; i < this.diagnoseBaseDate
									.length; i++) {
									if (content[item] == this.diagnoseBaseDate[i]) {
										this.basicInformation[item] = content[item];
										break;
									} else {
										this.basicInformation[item] = '';
									}
								}
							} else {
								if (describe == 'searchMatch') {
									if (item == 'valuationReportId' || item == 'id') {
										this.basicInformation[item] = '';
									} else {
										this.basicInformation[item] = content[item] ? content[item] :
											'';
									}
								} else {
									this.basicInformation[item] = content[item] ? content[item] : '';
								}
								if (item == 'industry') {
									var params = {
										listedCode: 0
									}
									if (this.$route.query.type == 5) {
										params.year = this.$route.query.year
									}
									this.$post(this.projectName + 'industry/qryOneIndustry', qs
											.stringify(params))
										.then((res) => {
											var codeList = res.data.content;
											codeList.forEach((item, idx) => {
												if (item.code == content['industry']) {
													this.industryGroup(content.industry,
														content
														.industryChildName); //调用二级行业
												}
											})
										});
								}
							}

						}
					});
				});

			},
			close: function() {
				this.autoDodge = false;
				if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) {
					$('.baseContent').css('margin-top', '0');
				}
			},
			displayThousandths: function(e, val, key) {
				this.pageModify('true');
				e.currentTarget.style.border = "1px solid #00E4FF";
				var y = String(val).indexOf(".") + 1; //获取小数点的位置
				var count = String(val).length - y; //获取小数点后的个数

				if (!val) {
					this.basicInformation[key] = "";
				} else {
					if (count > 2) {
						this.$set(this.basicInformation, key, Math.round(Number(val) * 100) / 100)
					}
				}

				if (key == 'finance' || key == 'ratio') {
					if (this.basicInformation.finance && this.basicInformation.ratio) {
						this.basicInformation.selfValue = (Number(this.basicInformation.finance) / (Number(this.basicInformation
							.ratio) / 100)).toFixed(2)
					}
				}

				if (e.currentTarget.parentNode.lastChild.innerHTML == '万元') {
					if (val <= 2147483647 && val >= -2147483648) {
						// console.log('符合')
					} else {
						this.basicInformation[key] = "";
						this.myAlert = true;
						this.popButonType = 'notEmpty';
						this.markedWords = '请输入合理数值';
						return false;
					}
					e.currentTarget.nextSibling.style.display = 'block';
				}
				//调用缓存共用方法
				if (!this.$route.query.reportId || (this.$route.query.reportId && !this.contentData) || (this.$route.query
						.reportId && this.contentData && this.isCacheValue == '0')) {
					this.method.caching("baseInfo", JSON.stringify(this.basicInformation), this.$route.query.type, this.$route
						.query.stage, this)
				}
			},
			inputFocus: function(e) {
				e.currentTarget.style.display = 'none';
				e.currentTarget.previousSibling.style.display = 'block';
				e.currentTarget.previousSibling.focus();
			},
			oneIndustry: function() { //一级行业
				var params = {
					listedCode: 0
				}

				this.$post(this.projectName + 'industry/qryOneIndustry', qs.stringify(params))
					.then((res) => {
						this.firstIndustry = res.data.content;
					});
			},
			industryGroup: function(code, name) {
				var params = {
					industryCode: code
				}

				this.$post(this.projectName + 'industry/qryTwoIndustry', qs.stringify(params))
					.then((res) => {
						this.secondIndustry = res.data.content;
						this.basicInformation.industryChildName = name;
					});
			},
			industryChange: function(val) {
				this.pageModify('true');
				for (var i = 0; i < this.firstIndustry.length; i++) {
					if (val == this.firstIndustry[i].name) {
						this.basicInformation.industry = this.firstIndustry[i].code;
						this.industryGroup(this.firstIndustry[i].code, '')
						break;
					}
				}
				// console.log("12")
				document.querySelectorAll('.empty')[7].querySelector('.ivu-select-selection').style.borderColor = '#00E4FF'

				//调用缓存共用方法
				if (!this.$route.query.reportId || (this.$route.query.reportId && !this.contentData) || (this.$route.query
						.reportId && this.contentData && this.isCacheValue == '0')) {
					this.method.caching("baseInfo", JSON.stringify(this.basicInformation), this.$route.query.type, this.$route
						.query.stage, this)
				}

			},
			market: function() {
				document.querySelectorAll('.empty')[5].querySelector('.ivu-select-selection').style.borderColor = '#00E4FF'
			},
			secondIndustryChange: function(val) {
				this.pageModify('true');
				for (var i = 0; i < this.secondIndustry.length; i++) {
					if (val == this.secondIndustry[i].name) {
						this.basicInformation.industryChild = this.secondIndustry[i].code;
						break;
					}
				}
				document.querySelectorAll('.empty')[8].querySelector('.ivu-select-selection').style.borderColor = '#00E4FF'
				//调用缓存共用方法
				if (!this.$route.query.reportId || (this.$route.query.reportId && !this.contentData) || (this.$route.query
						.reportId && this.contentData && this.isCacheValue == '0')) {
					this.method.caching("baseInfo", JSON.stringify(this.basicInformation), this.$route.query.type, this.$route
						.query.stage, this)
				}

			},



			// 关闭弹出层  点击确定回调
			clickConfirmJump() {
				this.getBaseInfo('0');
				this.$router.push({
					path: '/home',
					query: {
						stage: this.$route.query.stage,
						type: this.$route.query.type,
						reportId: this.$route.query.reportId,
					}
				})
			},


			//是  载入缓存
			clickCacheTrueMaskJump() {
				sessionStorage.isHistoryCache = 1;
				this.getBaseInfo();
			},

			//否  载入缓存
			clickCacheMaskJump() {
				sessionStorage.isHistoryCache = 1;
				this.method.clearCaching(this.$route.query.type, this.$route.query.stage, this, 'getBaseInfo')
				// this.getBaseInfo();
			},
			// 回滚到原位
			closeMaskInfoJump() {
				var emptyValue = this.empty

				// 成立日期
				if (this.empty == 3) {
					document.querySelectorAll('.empty')[emptyValue].querySelector('.ivu-date-picker').style.borderColor = 'red'
				} else if (this.empty == 6) {
					document.querySelectorAll('.empty')[emptyValue].querySelector('textarea').style.borderColor = 'red'
				} else if (this.empty == 7 || this.empty == 8 || this.empty == 5) {
					document.querySelectorAll('.empty')[emptyValue].querySelector('.ivu-select-selection').style.borderColor =
						'red'
				} else {
					document.querySelectorAll('.empty')[emptyValue].querySelector('input').style.borderColor = 'red'
				}

				var refxqBox = document.querySelector("#xqBox")
				refxqBox.scrollTop = document.querySelectorAll('.empty')[emptyValue].offsetTop - "140";

			}
		}

	}
</script>

<style scoped lang="less">
	.highlightRemarks {
		width: 100%;
		font-size: 14px;
		color: #fff;
		overflow: hidden;
	}

	.highlightRemarks p {
		float: left;
		line-height: 35px;
	}

	.reRed {
		color: #FE0000;
		padding-left: 5px;
		padding-right: 5px;
	}

	.relevantQuestion li {
		width: 45%;
		// margin-top: 20px;
	}

	.relevantQuestion li:nth-child(even) {
		float: left;
	}

	.relevantQuestion li:nth-child(odd) {
		float: right;
	}

	.relevantQuestion li label {
		width: 100%;
		display: block;
		font-size: 14px;
		color: #fff;
		margin-top: 20px;
		line-height: 20px;
	}

	.baseRight {
		width: 100%;
		margin-top: 5px;
		position: relative;
	}

	.baseRight input {
		width: 100%;
		height: 35px;
		border: 1px solid #00E4FF;
		border-radius: 3px;
		padding-left: 4%;
		padding-right: 4%;
		font-size: 14px;
		background-color: transparent;
		color: #fff;
	}

	.baseRight textarea {
		width: 100%;
		height: 80px;
		border: 1px solid #00E4FF;
		border-radius: 3px;
		padding-left: 1.5%;
		padding-right: 1.5%;
		font-size: 14px;
		background-color: transparent;
		color: #fff;
		padding-top: 10px;
	}

	.baseRight .measure {
		color: #fff;
		position: absolute;
		right: 10px;
		top: 11px;
	}


	/deep/ .ivu-input-small,
	/deep/ .ivu-select-selection .ivu-select-placeholder,
	/deep/ .ivu-select-single .ivu-select-selection .ivu-select-placeholder,
	/deep/ .ivu-select-single .ivu-select-selection .ivu-select-selected-value,
	/deep/ .ivu-select-selection>div {
		color: #fff;
		font-size: 14px;
		background-color: transparent !important;
		border: none;
		height: 35px;
		line-height: 35px;
	}

	// /deep/ .ivu-date-picker .ivu-select-dropdown{
	//     width: 100%;
	// }
	// /deep/ .ivu-date-picker-cells{
	//   width: 100%;
	// }
	// /deep/ .ivu-picker-panel-body{
	//   width: 100%;
	// }
	/deep/ .ivu-date-picker {
		width: 100%;
		height: 35px !important;
		line-height: 32px;
		border: 1px solid #00E4FF;
		border-radius: 3px;
		background-color: transparent !important;
	}

	/deep/ .ivu-select-single .ivu-select-selection {
		width: 100%;
		height: 35px !important;
		line-height: 35px;
		border: 1px solid #00E4FF;
		border-radius: 3px;
		background-color: transparent !important;
	}

	/deep/ .ivu-select-item {
		width: 100% !important;
		font-size: 14px !important;
		// border: 1px solid #00E4FF;
		border-radius: 3px;
	}

	/deep/ .ivu-input-suffix i {
		width: 100%;
		margin-top: 6px;
	}

	/deep/ .ivu-select-arrow {
		color: #fff !important;
	}

	/deep/ .ivu-input-suffix i {
		color: #fff !important;
	}

	.ivu-picker-panel-body {
		width: 100% !important;
	}





	.desc-container {
		position: relative;
		display: inline-block;
		cursor: pointer;
	}

	.desc-main {
		position: absolute;
		top: 0;
		line-height: 1;
		width: 190px;
		z-index: 2;
	}

	.desc-container .desc-main>span:before {
		content: "";
		position: absolute;
		border-right: 6px solid transparent;
		border-left: 6px solid transparent;
		border-bottom: 9px solid #ECC641;
		border-top: 9px solid transparent;
		top: 5px;
		left: 4px;
		z-index: 7;
		display: none;
	}

	.desc-container .desc-main>span:after {
		content: "";
		position: absolute;
		border-right: 5px solid transparent;
		border-left: 5px solid transparent;
		border-bottom: 8px solid #FEEEB4;
		border-top: 6px solid transparent;
		top: 10px;
		left: 5px;
		z-index: 45;
		display: none;
	}

	.desc-container .desc-main>p {
		background: #FEEEB4;
		padding: 13px 10px 13px 15px;
		position: absolute;
		top: 23px;
		left: 4px;
		border: 1px solid #ECC641;
		line-height: 1.5;
		border-radius: 4px;
		border-top-left-radius: 0;
		display: none;
		color: #282828;
		font-size: 12px;
	}

	.desc-container:hover .desc-main>span:after {
		display: block;
	}

	.desc-container:hover .desc-main>span:before {
		display: block;
	}

	.desc-container:hover .desc-main>p {
		display: block;
	}

	.relevantQuestion li label i {
		display: inline-block;
		width: 12px;
		height: 12px;
		background: #ffa446;
		color: #fff;
		text-align: center;
		line-height: 13px;
		font-size: 12px;
		margin-left: 2px;
		margin-top: -14px;
		border-radius: 6px;
		cursor: pointer;
	}

	.companyRevenueDate {
		width: 45px;
		background-color: transparent;
		color: #fff;
		font-size: 14px;
	}

	.companyRevenueRight {
		position: relative;
	}

	.showInput {
		width: 99%;
		// width: 100%;
		height: 32px;
		line-height: 32px;
		border-radius: 3px;
		font-size: 14px;
		// background-color: transparent;
		color: #fff;
		position: absolute;
		top: 0px;
		left: 0%;
		background-color: #0d01a7;
		margin-top: 2px;
		margin-left: 0.5%;
		display: none;
		padding-left: 4%;
		// background-color:rgba(220,38,38,0);

		// opacity: 1;
	}

	.relevantQuestion .readonColor {
		background-color: #0005e0;
	}

	.infoMask {
		width: 100%;
		height: 100%;
		position: fixed;
		background-color: #000;
		opacity: 0.3;
		z-index: 999;
	}

	.tooltipState {
		width: 60%;
		// line-height: 40px;
		color: #fff;
		font-size: 12px;
		border: 1px solid #c56d6d;
		color: #ea403e;
		border-radius: 5px;
		padding-left: 10px;
		margin: auto;
		// padding-top: 20px;
		// margin-top: 20px;
		float: left;
		margin-left: 60px;
		padding-top: 10px;
		padding-bottom: 10px;
		position: relative;
		// background-color: rgba(255,201,198,.2);
	}

	.cuowu {
		position: absolute;
		width: 13px;
		right: 10px;
		top: 14px
	}

	input:focus,
	textarea:focus {
		outline: none;
	}

	// /deep/ .ivu-modal-content{
	// 	width: 80%;
	// 	height: 50%;
	// 	background:url("../../assets/img/filledTuring/tishi.png");
	// 	background-size: 100% 100%;
	// 	opacity: 999999999;
	// }

	/deep/ .ivu-select-dropdown {
		background-color: #fff;
		position: absolute;
		left: 0px !important;
		top: 33px !important;
		z-index: 999;
	}
</style>