<template>
    <div id="app" class="signIn">
        <!-- 导航栏-->
        <header class="back-white">
			<div class="flow-header-container topone">
				<div class="logo">
					<router-link to="/home?stage=2&type=12" tag="a"></router-link>
				</div>
				<div class="header-two-right">
					<div class="country-container">
						<div class="china active"> <span class="country-name">中国</span> <span
								class="country-img countryImgActive"></span> </div>
					</div>
					<router-link to="/home?stage=2&type=12" tag="a" class="router-link-active">首页</router-link>
				</div>
			</div>
		</header>
        <div class="mobile_header">
            <img src="../../assets/img/logo-08.svg" @click="toIndex()"/>
            <button @click="toLogin()">登录已有账号</button>
        </div>
        <div style="overflow: hidden;" class="pc_register">
            <div class="register">
                <div class="registerContent">
                    <form action="">
                        <div class="registerTitle">欢迎注册心流慧估</div>
                        <p class="accountNumber">
                            <span>已有账号？</span>
                            <span class="accountLogin" @click="toLogin()">登录</span>
                        </p>
                        <ul class="ulClass">
                            <div class="choosePhone">
                                <li class="phone">
                                    <!--<label for="phone">中国大陆 +86</label>-->
                                    <div class="phoneSele">
                                        <select>
                                            <option value="1">中国大陆 +86</option>
                                            <option value="2">中国香港 +87</option>
                                            <option value="3">中国澳门 +88</option>
                                            <option value="4">中国台湾 +89</option>
                                        </select>
                                    </div>
                                    <input type="text" id="phone" placeholder="手机号" v-model="register.loginName" @blur='phoneVerify()'>
                                </li>
                                <div class="messageBox">
                                    <p v-show="phoneStatus == 0" class="suggest">
                                        <i>!</i>
                                        <span>建议使用常用手机，方便手机登录和找回密码</span>
                                    </p>
                                    <p v-show="phoneStatus == 1" class="format">
                                        <i class="remindIcon">!</i>
                                        <span>请输入合法的手机号</span>
                                    </p>
                                    <p v-show="phoneStatus == 2" class="format">
                                        <i class="remindIcon">!</i>
                                        <span>该账号已注册</span>
                                    </p>
                                    <p v-show="phoneStatus == 3" class="message">
                                        <i class="remindIcon">!</i>
                                        <span>手机号已占用 ▪</span>
                                        <span class="returnAccount" @click="toForgetPassword()">找回账号？</span>
                                    </p>
                                </div>
                            </div>
                            <li class="password pictureVerification">
                                <input type="text" id="pictureCode" placeholder="图片验证码" v-model="register.pictureCode" @blur="pictureCodeVerify()">
                                <div>
                                    <img class="picture" alt="图形验证码-心流" :src="uuPicCode">
                                    <span class="pictureCodeBtn" @click="getPictureCode()">换一张</span>
                                </div>
                            </li>
                            <div class="messageBox">
                                <p class="message" v-show="pictureCodeStatus == 1">
                                    <i class="remindIcon">!</i>
                                    <span class="pictureMessage">验证码错误</span>
                                </p>
                                <p class="message" v-show="pictureCodeStatus == 2">
                                    <i class="remindIcon">!</i>
                                    <span class="pictureMessage">请输入验证码</span>
                                </p>
                            </div>

                            <li class="password">
                                <input type="password" id="password" placeholder="密码" v-model="register.password" @blur="passwordVerify()">
                            </li>
                            <div class="messageBox">
                                <p class="suggest" v-show="passwordStatus == 0">
                                    <i>!</i>
                                    <span>密码长度6-16位，支持数字、字母</span>
                                </p>
                                <p class="message" v-show="passwordStatus == 1">
                                    <i class="remindIcon">!</i>
                                    <span>密码格式有误</span>
                                </p>
                                <p class="message" v-show="passwordStatus == 2">
                                    <i class="remindIcon">!</i>
                                    <span>请输入密码</span>
                                </p>
                            </div>
                            <li class="password">
                                <input type="password" id="againPassword" placeholder="确认密码" v-model="register.password2" @blur="passwordVerify_Second()">
                            </li>
                            <div class="messageBox">
                                <p class="suggest" v-show="passwordStatus_second == 0">
                                    <i>!</i>
                                    <span>请再次输入密码</span>
                                </p>
                                <p class="message" v-show="passwordStatus_second == 1">
                                    <i class="remindIcon">!</i>
                                    <span>两次输入的密码不一致，请重新输入</span>
                                </p>
                                <p class="message" v-show="passwordStatus_second == 2">
                                    <i class="remindIcon">!</i>
                                    <span>请再次输入密码</span>
                                </p>
                            </div>
                            <li class="password verificationCode">
                                <input type="text" id="code" placeholder="验证码" v-model="register.code" @blur="phoneCodeVerify()">
                                <input class="sendCode" v-model="phoneCodeText" @click="sendPhoneCode()" :disabled="phoneCodeText=='发送验证码' ? false:true">
                            </li>
                            <div class="messageBox">
                                <p class="message" v-show="phoneNumCodeStatus == 1">
                                    <i class="remindIcon">!</i>
                                    <span class="pictureError">验证码错误</span>
                                </p>
                                <p class="message" v-show="phoneNumCodeStatus == 2">
                                    <i class="remindIcon">!</i>
                                    <span class="pictureError">发送失败</span>
                                </p>
                                <p class="message" v-show="phoneNumCodeStatus == 3">
                                    <i class="remindIcon">!</i>
                                    <span class="pictureError">请输入验证码</span>
                                </p>
                            </div>
                            <!--<li class="password">-->
                            <!--<input type="text" placeholder="我是：">-->
                            <!--</li>-->
                            <div class="lookAfter">
                                <label>寻求:</label>
                                <span @click="register.serviceType = 1" :class="{sele:register.serviceType == 1}" data-typeCode="1">找投资</span>
                                <span @click="register.serviceType = 2" :class="{sele:register.serviceType == 2}" data-typeCode="2">找融资</span>
                                <span @click="register.serviceType = 3" :class="{sele:register.serviceType == 3}" data-typeCode="3">找服务</span>
                                <span @click="register.serviceType = 99" :class="{sele:register.serviceType == 99}" data-typeCode="99">随便看看</span>
                            </div>
                            <div class="agree">
                                <img src="../../assets/img/login/agree.png" alt="同意icon-心流" v-show="checkStatus" @click="checkStatus = !checkStatus">
                                <img src="../../assets/img/login/disagree.png" alt="不同意icon-心流" v-show="!checkStatus" @click="checkStatus = !checkStatus">
                                <label>我已阅读并同意<span @click="registration = 1;active = 1;">《心流网注册协议》</span>、<span @click="disclaimer = 1;active = 1;">《免责声明》</span><span style="color:#bfbfbf;text-indent:1.4em">和</span><span @click="confidentiality = 1;active = 1;">《隐私政策》</span></label>
                            </div>
                            <div class="btn" @click="registerCommit()">立即注册</div>
                        </ul>
                    </form>
                </div>
            </div>
        </div>
        <div class="mobile_register">
            <h3>账号注册</h3>
            <div class="infoItem phoneNumItem">
                <div class="phoneSele">
                    <select>
                        <option value="1">中国大陆 +86</option>
                        <option value="2">中国香港 +87</option>
                        <option value="3">中国澳门 +88</option>
                        <option value="4">中国台湾 +89</option>
                    </select>
                    <input type="text" v-model="register.loginName" placeholder="手机号" @blur='phoneVerify()' >
                </div>
                <div class="messageBox">
                    <p v-show="phoneStatus == 1" class="format">
                        <i class="remindIcon">!</i>
                        <span>请输入合法的手机号</span>
                    </p>
                    <p v-show="phoneStatus == 2" class="format">
                        <i class="remindIcon">!</i>
                        <span>该账号已注册</span>
                    </p>
                    <p v-show="phoneStatus == 3" class="message">
                        <i class="remindIcon">!</i>
                        <span>手机号已占用 ▪</span>
                        <span class="returnAccount" @click="toForgetPassword()">找回账号？</span>
                    </p>
                </div>
            </div>
            <div class="infoItem pictureCodeItem">
                <div>
                    <input type="text" placeholder="图片验证码" v-model="register.pictureCode" @blur="pictureCodeVerify()">
                    <img alt=""  :src="uuPicCode" >
                    <div class="changeOne"  @click="getPictureCode()">换一张</div>
                </div>
                <div class="messageBox">
                    <p class="message" v-show="pictureCodeStatus == 1">
                        <i class="remindIcon">!</i>
                        <span class="pictureMessage">验证码错误</span>
                    </p>
                    <p class="message" v-show="pictureCodeStatus == 2">
                        <i class="remindIcon">!</i>
                        <span class="pictureMessage">请输入验证码</span>
                    </p>
                </div>
            </div>
            <div class="infoItem passwordItem">
                <input type="text" placeholder="密码" v-model="register.password" @blur="passwordVerify()">
                <div class="messageBox">
                    <p class="message" v-show="passwordStatus == 1">
                        <i class="remindIcon">!</i>
                        <span>密码格式有误</span>
                    </p>
                    <p class="message" v-show="passwordStatus == 2">
                        <i class="remindIcon">!</i>
                        <span>请输入密码</span>
                    </p>
                </div>
            </div>
            <div class="infoItem password2Item">
                <input type="text" placeholder="确认密码" v-model="register.password2" @blur="passwordVerify_Second()">
                <div class="messageBox">
                    <p class="message" v-show="passwordStatus_second == 1">
                        <i class="remindIcon">!</i>
                        <span>两次输入的密码不一致，请重新输入</span>
                    </p>
                    <p class="message" v-show="passwordStatus_second == 2">
                        <i class="remindIcon">!</i>
                        <span>请再次输入密码</span>
                    </p>
                </div>
            </div>
            <div class="infoItem codeItem">
                <div class="codeSele">
                    <input type="text"  placeholder="验证码" v-model="register.code" @blur="phoneCodeVerify()">
                    <input id="sendCode" class="sendCode" v-model="phoneCodeText" @click="sendPhoneCode()" :disabled="phoneCodeText=='发送验证码' ? false:true" readonly>
                </div>
                <div class="messageBox">
                    <p class="message" v-show="phoneNumCodeStatus == 1">
                        <i class="remindIcon">!</i>
                        <span class="pictureError">验证码错误</span>
                    </p>
                    <p class="message" v-show="phoneNumCodeStatus == 2">
                        <i class="remindIcon">!</i>
                        <span class="pictureError">发送失败</span>
                    </p>
                    <p class="message" v-show="phoneNumCodeStatus == 3">
                        <i class="remindIcon">!</i>
                        <span class="pictureError">请输入验证码</span>
                    </p>
                </div>
            </div>
            <div class="lookAfter">
                <label>寻求:</label>
                <span @click="register.serviceType = 1" :class="{sele:register.serviceType == 1}" data-typeCode="1">找投资</span>
                <span @click="register.serviceType = 2" :class="{sele:register.serviceType == 2}" data-typeCode="2">找融资</span>
                <span @click="register.serviceType = 3" :class="{sele:register.serviceType == 3}" data-typeCode="3">找服务</span>
                <span @click="register.serviceType = 99" :class="{sele:register.serviceType == 99}" data-typeCode="99">随便看看</span>
            </div>
            <div class="agree">
                <img src="../../assets/img/login/agree.png" alt="同意icon-心流" v-show="checkStatus" @click="checkStatus = !checkStatus">
                <img src="../../assets/img/login/disagree.png" alt="不同意icon-心流" v-show="!checkStatus" @click="checkStatus = !checkStatus">
                <label>我已阅读并同意<span @click="toRegistration()">《心流网注册协议》</span>、<span @click="toDisclaimer()">《免责声明》</span>和<span @click="toConfidentiality()">《隐私政策》</span></label>
            </div>
            <div class="btn" @click="registerCommit()">立即注册</div>
        </div>
        <div class="active_Bg" v-show="active">
            <!--注册协议-->
            <div class="agreement registration" v-show="registration">
                <img class="img_bg" src="../../assets/img/guzhi/agreement_re.png" alt="注册协议-心流" />
                <div class="text_content">
                    <img class="logo_title" src="../../assets/img/login/logo@3x.png" alt="心流logo-心流" />
                    <img class="close" src="../../assets/img/guzhi/close.png" alt="关闭icon-心流" @click="closeActive()"/>
                    <p>心流网注册协议</p>
                    <div class="content agreementContent">
                        <p>本服务协议（以下简称“本协议”）由您个人或您所代表的单位（以下统称“您”）和北京心流慧估科技有限公司（以下简称 “本公司”）签订，请仔细阅读本协议。</p>
                        <p>心流网服务（以下简称“本服务”）是指由本公司以及本公司关联方通过心流网网站（即www.flcccc.com等域名所指向的网站）、微信、APP或其他平台或软件、应用程序等（以下统称“心流网”）以线上或线下方式向用户提供的企业估值服务及其他相关服务，包括但不限于提供相关页面、内容、产品等服务。</p>
                        <p>本协议中，关联方包括关联公司和关联人以及通过心流网向您提供内容、产品或服务的第三方，在出现下列任一情况时，任何实体应被视为本协议相关一方的关联公司、关联人：（1）直接或间接控制本协议一方、被本协议一方控制或与本协议一方同受其他主体控制的任何实体；或（2）注册资本、投票权、股权或决策权的百分之十（10%）或以上由本协议一方直接或间接拥有（反之亦然）；或（3）本协议任何一方通过合同约定、选任董事或其他方式指导、影响或制定该实体的决策、发展、管理和政策的方向（反之亦然）；或（4）由本协议一方的关联人担任董事、合伙人、股东、高管的任何实体；“关联人”是指自然人的近亲属，包括父母、配偶、亲兄弟姐妹及其配偶、成年子女及其配偶。</p>
                        <h4>一、总则</h4>
                        <p>1、您确认：在您注册成为心流网用户并接受本服务之前，您已充分阅读、理解并接受本协议的全部内容，一旦您选择“同意”并完成注册之后，进行购买或使用本服务（包括浏览网页，制作、下载或上传文件等各种使用心流网的情形，下同），即表示您同意遵循本协议之所有约定。</p>
                        <p>2、您同意，本公司有权随时对本协议内容进行单方面的变更，并以网站内容变更的方式予以公布，不再另行单独通知您；若在本协议内容修改后，您继续使用本服务的，表示您已充分阅读、理解并接受修改后的协议内容，也将遵循修改后的协议内容使用本服务；若您不同意变更后的协议内容，应立即停止使用本服务，否则您应自行承担因继续使用本服务而产生的所有后果。</p>
                        <p>3、您声明，在您同意接受本协议并注册成为心流网用户时，具有法律规定的完全民事权利能力和民事行为能力，您是能够独立承担民事责任的自然人、法人或其他组织；本协议内容不受您所属国家或地区法律的排斥。不具备前述条件的，您应立即终止注册或停止使用本服务，否则您应当自行承担因此产生的所有后果。</p>
                        <h4>二、本服务简介</h4>
                        <p>心流网向注册用户提供企业估值服务及其相关服务，心流网所提供的相关服务说明详见www.flcccc.com相关产品及服务介绍。</p>
                        <h4>三、心流网账户</h4>
                        <p>1、 注册相关</p>
                        <p>在使用本服务前，您必须先行注册，取得本公司提供给您的心流网账户（以下简称“心流网账户”），您同意并保证：</p>
                        <p>(1)您所填写的用户信息是真实、准确、完整、及时的，并且没有任何引人误解或者虚假的陈述，且保证心流网可以通过您所填写的联系方式与您取得联系。</p>
                        <p>(2)您应根据心流网对于相关服务的要求，及时提供相应的身份证明等资料，否则心流网有权拒绝向您提供相关服务。</p>
                        <p>(3)您承诺将及时更新您的用户信息并维持该等信息的有效性。</p>
                        <p>(4)如果您提供的资料或信息包含有不正确、不真实的信息，心流网有权保留取消您的用户资格并决定随时结束为您提供服务的权利。</p>
                        <p>2、 账户安全</p>
                        <p>您将对使用心流网账户及密码进行的一切操作及言论行为负完全的责任，您同意：</p>
                        <p>(1)本公司通过您的用户名和密码识别您的指示，请您妥善保管您的用户名和密码，对于因密码泄露所致的损失，由您自行承担。您保证不向其他任何个人或机构泄露心流网账户及密码，亦不将在心流网注册获得的账户借给他人使用，否则您应承担由此产生的全部责任，并与实际使用人承担连带责任。</p>
                        <p>(2)如您发现他人冒用或盗用您的账户及密码或任何其他未经您合法授权之情形时，应立即以有效方式通知本公司并要求本公司暂停相关服务。同时，您理解本公司对您的请求采取行动需要合理期限，在此之前，本公司对已执行的指令及所导致的损失不承担任何责任。</p>
                        <p>(3)为明确起见，如果您是企业用户，应当谨慎授权并妥善管理实际操作企业账户及密码的人员，防止该等人员泄露和许可他人使用贵企业信息和账户的情形，尤其需要避免该等人员离开企业之后可能出现的失去账户控制权的情形。本公司对您管理不善所导致的损失不承担任何责任。</p>
                        <h4>四、心流网服务使用守则</h4>
                        <p>为有效保障您使用本服务的合法权益，您理解并同意接受以下规则：</p>
                        <p>1、您在使用本服务过程中，本协议内容、网页上出现的关于相关操作的提示或本公司发送到您手机的信息（短信或电话等）内容是您使用本服务的相关规则，您使用本服务即表示您同意接受本服务的相关规则。</p>
                        <p>2、您了解并同意本公司无须征得您的同意可单方修改服务的相关规则，服务规则应以您使用服务时的页面内容为准，您同意并遵照服务规则是您使用本服务的前提。根据国家法律法规变化及网站运营需要，本公司有权对本协议条款不时地进行修改，修改后的协议一旦被张贴在本站上，则立即生效并代替原来的协议内容。您可随时登录查阅最新协议；您有义务不时关注并阅读最新版的协议及网站公告。如您不同意更新后的协议，应立即停止接受心流网依据本协议提供的服务；如您继续使用心流网提供的服务的，即视为同意更新后的协议。本公司建议您在使用心流网之前，应阅读本协议及心流网的公告。</p>
                        <p>3、本公司可能会以系统通知、电子邮件或发送到您手机的短信或电话等方式通知您服务进展情况以及提示您进行下一步的操作，但本公司不保证您能够收到或者及时收到该等通知，且不对您没有及时收到该等通知承担任何责任。因此，在服务过程中您应当及时登录到心流网查看服务进展情况和进行相关操作。因您没有及时查看和对服务状态进行修改或确认或未能及时提供反馈和信息资料而导致的任何纠纷或损失，本公司不承担任何责任。</p>
                        <p>4、服务费用，在您使用本服务时，本公司有权依照相应的服务收费介绍、订单或相关协议要求您支付服务费用。本公司拥有制订及调整服务费用之权利，具体服务费用以您使用本服务时心流网上所列之收费方式公告或您与本公司或心流网上的服务提供方通过心流网达成的其他书面协议为准。</p>
                        <h4>五、您的权利和义务</h4>
                        <p>1、您有权利拥有自己在心流网的用户名和密码并有权利使用自己的用户名和密码随时登录心流网网站。</p>
                        <p>2、您有权利享受心流网提供的企业估值服务及其他相关服务，并有权利在接受心流网提供的服务时获得心流网的支持、咨询等服务，服务内容详见心流网相关产品介绍。</p>
                        <p>3、您保证不会利用技术或其他手段破坏及扰乱心流网网站和服务以及心流网其他客户的网站和服务。</p>
                        <p>4、您应尊重心流网及其他第三方的知识产权和其他合法权益，并保证在发生侵犯上述权益的违法或违约事件时，您将尽力保护本公司及关联方、雇员、合作伙伴等免于因该等事件受到影响或损失；心流网保留您侵犯上述各方合法权益时终止向您提供服务的权利。</p>
                        <p>5、对由于您向心流网提供的联络方式问题（如信息有误或未保持畅通或未能及时查看信息）以及您用于接受心流网邮件的电子邮箱安全性、稳定性不佳而导致的一切后果，您应自行承担责任，包括但不限于因您未能及时收到心流网的相关通知而导致的后果和损失。</p>
                        <p>6、您保证您使用心流网服务时将遵从国家、地方法律法规、行业惯例和社会公共道德，不会利用心流网提供的服务进行违反国家法律、法规、政策的行为。您同意心流网有权在您违反上述规定时终止向您提供服务，因您上述行为给心流网造成损失的，您应承担赔偿责任。</p>
                        <h4>六、心流网的权利和义务</h4>
                        <p>1、心流网应根据您选择的服务以及交纳款项（如需）的情况向您提供合格的企业估值服务及其他相关服务。</p>
                        <p>2、心流网承诺对您的资料采取对外保密措施，不向第三方披露您的资料，不授权第三方使用您的资料，除非：</p>
                        <p>(1)依据本协议条款或者您与心流网之间其他服务协议、合同等规定可以提供的。</p>
                        <p>(2)依据法律法规的规定应当提供的。</p>
                        <p>(3)行政、司法等职权部门依职权要求心流网提供的。</p>
                        <p>(4)您同意心流网向第三方提供的。</p>
                        <p>(5)心流网为解决举报事件、提起诉讼而提交的。</p>
                        <p>(6)心流网为防止严重违法行为或涉嫌犯罪行为的发生而采取必要合理行动所必须提交的。</p>
                        <p>(7)心流网为向您提供产品、服务、信息而向第三方提供您的资料和信息或通过第三方向您提供产品、服务、咨询而使用您的资料和信息时，在此情况下，心流网有权向上述第三方提供。</p>
                        <p>3、心流网及本公司关联方有权使用您的资料和信息，包括但不限于通过邮件、短信或电话等形式并有权向您发送注册登录信息、订单信息、服务要求、促销活动等告知信息。</p>
                        <p>4、心流网保留在您违反国家、地方法律法规规定或违反本协议条款的情况下终止为您提供服务并终止您账户的权利。</p>
                        <p>5、心流网有权使用您的注册信息、用户名、密码等信息，登录进入您的注册账户，进行证据保全，包括但不限于公证、见证等。</p>
                        <h4>七、隐私及其他个人信息的保护</h4>
                        <p>一旦您同意本协议或使用本服务，您即同意本公司按照以下条款来使用和披露您的个人信息。</p>
                        <p>1、用户名和密码</p>
                        <p>在您注册为心流网用户时，我们会要求您设置用户名和密码来识别您的身份。您仅可通过您设置的密码来使用心流网账户，如果您泄漏了密码，您可能会丢失您的个人识别信息，并可能导致对您不利的法律后果。心流网账户和密码因任何原因受到潜在或现实危险时，您应该立即和本公司取得联系，在本公司采取行动前，本公司对此不负任何责任。</p>
                        <p>2、 注册信息</p>
                        <p>您注册心流网账户时应向本公司提供您的真实姓名或名称以及联系方式（如地址、手机号码和电子邮件地址），您还可以选择来填写相关附加信息，以便于心流网为有针对性地向您提供更加有效的服务和机会，您了解并同意本公司及其关联方将通过您的电子邮件地址或该手机通知您这些信息。</p>
                        <p>3、 手机信息</p>
                        <p>您应妥善保护您的手机，一旦您手机出借、被盗、遗失或被他人窥视等，均可能引起您心流网账户相关个人信息之外泄，心流网对此不承担任何责任。</p>
                        <p>4、 银行账户信息</p>
                        <p>若本公司所提供的服务需要您提供您的银行账户或其他支付工具信息以及支付相关信息（如支付历史记录、账单及互动信息），在您提供相应信息后，本公司将严格履行相关保密约定。</p>
                        <p>5、 登录记录</p>
                        <p>为了保障您使用本服务的安全以及保障公司不断改进服务质量，本公司将记录并保存您登录和使用本服务的相关信息，本公司承诺不将此类信息提供给任何第三方，但双方另有约定或法律法规另有规定或本公司提供给本公司关联方的情形除外。</p>
                        <p>6、 数据统计</p>
                        <p>本公司会对心流网用户的身份数据进行综合统计，并基于市场调查、销售或优惠奖励的需要进行使用或披露。</p>
                        <p>7、 第三方</p>
                        <p>心流网含有到其他网站的链接或第三方产品或服务推荐，但本公司对第三方的隐私保护措施不承担任何责任。</p>
                        <p>八、系统中断或故障</p>
                        <p>系统因下列状况无法正常运转，使您无法使用各项服务时，本公司不承担损害赔偿责任，该状况包括但不限于：</p>
                        <p>1、 本公司在心流网公告之系统停机维护期间。</p>
                        <p>2、 第三方提供的基础设施出现故障不能进行数据传输的。</p>
                        <p>3、 因台风、地震、海啸、洪水、停电、战争、恐怖袭击等不可抗力之因素，造成本公司系统障碍不能执行业务的。</p>
                        <p>4、 由于黑客攻击、电信部门技术调整或故障、网站升级、银行方面的问题等原因而造成的服务中断或者延迟。</p>
                        <p>九、责任范围及责任限制</p>
                        <p>1、 心流网依据国际通用的企业估值模型和方法通过网络为用户提供非法定的企业估值服务，心流网提供的所有计算模型均由专业分析师准备和审阅，但用户使用心流网制作及计算出来的文件和数据仅供参考，并非最终文件，用户可以自行修改，心流网特别建议用户根据实际情况进行审查后再使用，心流网和本公司就此并不承担任何法律责任。</p>
                        <p>2、 心流网用户信息是由您本人自行提供的，本公司无法保证该信息之真实、准确、及时和完整，您应对您的判断承担全部责任。</p>
                        <p>3、 您自本公司及本公司工作人员或经由本服务取得之建议和资讯，无论其为书面或口头形式，均不构成本公司对本服务之保证。</p>
                        <p>4、 在法律允许的情况下，本公司对于与本协议有关或由本协议引起的任何间接的、惩罚性的、特殊的、派生的损失（包括业务损失、收益损失、利润损失、使用数据或其他经济利益的损失），不论是如何产生的，也不论是由对本协议的违约（包括违反保证）还是由侵权造成的，均不负有任何责任，即使事先已被告知此等损失的可能性。另外即使本协议规定的排他性救济没有达到其基本目的，也应排除本公司对上述损失的责任。</p>
                        <p>5、 除本协议另有规定外，在任何情况下，本公司对本协议所承担的违约赔偿责任总额以本公司向您收取的当次服务费用（如有）为上限。</p>
                        <h4>十、知识产权</h4>
                        <p>1、 心流网上所有心流网程序或内容，包括但不限于图片、档案、资讯、数据、信息、资料、网站架构、网站画面的安排、网页设计以及系统根据您的输入生产的任何文件、信息或内容（“专有内容”），均由本公司或本公司关联方依法拥有其知识产权，包括但不限于著作权、商标权、专利权、商业秘密等。</p>
                        <p>2、 特此授权用户就其在问卷中所披露的特定项目通过心流网企业估值服务及其他相关服务使用和修改形成的项目文件（“允许使用范围”）。除前述允许使用范围之外，非经本公司事先书面同意，任何人不得擅自使用、修改、复制、公开传播、散布、发行或公开发表上述专有内容，也不得擅自指令或允许他人使用、修改、复制、公开传播、散布、发行或公开发表上述专有内容。</p>
                        <p>3、 尊重知识产权并遵守本第十条是您应尽的义务，如有违反，您应承担损害赔偿责任。</p>
                        <h4>十一、协议的解释和适用</h4>
                        <p>1、 本协议由本协议条款与心流网公示的各项规则组成，相关名词可互相引用参照，如有不同理解，以本协议条款为准。</p>
                        <p>2、 您对本协议理解和认同，您即对本协议所有组成部分的内容理解并认同，一旦您使用本服务，您和本公司即受本协议所有组成部分的约束。</p>
                        <p>3、 本协议部分内容被有管辖权的机关或机构认定为废止、无效或因任何理由不可执行，不因此影响其他内容的有效性和可执行性。</p>
                        <h4>十二、法律适用与管辖</h4>
                        <p>本协议的订立、执行和解释及争议的解决均应适用在中华人民共和国大陆地区适用之有效法律（但不包括其冲突法规则）。如发生本协议与适用之法律相抵触时，则这些条款将完全按法律规定重新解释，而其它有效条款继续有效。 如缔约方就本协议内容或其执行发生任何争议，双方应尽力友好协商解决；但如在60天内不能友好解决有关争议，任何一方均可提交北京仲裁委员会适用简易程序在北京裁决，仲裁裁决为终局裁定，对双方均有约束力。</p>
                    </div>
                </div>
            </div>
            <!-- 免责声明 -->
            <div class="agreement disclaimer" v-show="disclaimer">
                <img class="img_bg" src="../../assets/img/guzhi/agreementBg.png" alt="免责声明-心流" />
                <div class="text_content">
                    <img class="logo_title" src="../../assets/img/login/logo@3x.png" alt="心流logo-心流" />
                    <img class="close" src="../../assets/img/guzhi/close.png" @click="closeActive()" />
                    <p>免责声明</p>
                    <div class="content agreementContent">
                        <p>使用本站点前，请仔细阅读下列条款：</p>
                        <p>“心流网”（www.flcccc.com）是为用户提供线上企业股权估值及其他相关服务的网络平台。</p>
                        <p>1、心流网依据国际通用的估值模型和自主研发的估值指标体系，在线进行企业股权估值。所采用的数据均为用户提供和公开渠道可获取，心流网不对前述数据的准确性、完整性和真实性负有实质审核的义务。网站自动生成的相关报告，仅给用户提供参考股权估值区间，不能作为企业并购、转让等交易的法定依据，并不构成任何投资建议，投资者据此做出的任何投资决策与心流网无关。</p>
                        <p>2、用户应妥善保管用户账号和密码等个人信息，心流网承诺不向任何第三方泄露用户个人信息或其他商业信息。除非司法机关、监管机构或其他政府部门依据法律程序要求心流网提供，心流网将根据相关部门的要求提供相关信息资料，及时通知用户。</p>
                        <p>3、心流网挑选具备良好声誉的网站作为友情链接列入网站进行展示，供用户浏览和参考。但该行为并不视为心流网与所列示网站一定具有合作关系，请自行对相关网站的内容进行审慎辨别及判断，对于用户使用外部链接网站而引起的损失或损害，心流网不承担任何责任。</p>
                        <p>4、您使用心流网时，应遵守中国有关法律规定与本声明的内容，不得破坏心流网运行安全，不得利用心流网进行任何违法活动，不得损害心流网所有者的合法权益。</p>
                        <p>5、除转载信息外，心流网所有站内原创内容的著作权属于北京心流慧估科技有限公司所有。任何机构或者个人未经北京心流慧估科技有限公司书面许可，不得以任何形式使用前述内容。</p>
                        <p>您浏览和使用心流网即视为您接受以上条款。心流网在法律许可范围内有权对本声明进行解释和修改，并将以网站信息更新的方式另行通知。</p>
                    </div>
                </div>
            </div>
            <!-- 隐私政策 -->
            <div class="agreement confidentiality" v-show="confidentiality">
                <img class="img_bg" src="../../assets/img/guzhi/agreementBg.png" alt="免责声明-心流" />
                <div class="text_content">
                    <img class="logo_title" src="../../assets/img/login/logo@3x.png" alt="心流logo-心流" />
                    <img class="close" src="../../assets/img/guzhi/close.png" @click="closeActive()" />
                    <p>隐私政策</p>
                    <div class="content agreementContent">
                        <h3>本协议版本发布/更新日期为：2021年1月1日</h3>
                        <h3 style="text-align: center;">心流隐私政策</h3>
                        <h4>一、我们如何收集和使用您的个人/企业信息</h4>
                        <p>个人信息是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息，本隐私政策中涉及的个人信息包括个人电话号码、电子邮箱等。</p>
                        <p>企业信息指和该企业的注册和经营有关的信息，本隐私政策中涉及的企业信息包括企业基本信息、软性指标、财务报表、资产权属情况、财务清单、法务清单等使用智能股权价值服务系统（SEVS）产品所需要的各种信息。</p>
                        <p>我们会出于以下目的，收集和使用您的个人信息及您填写的企业信息：</p>
                        <h4>（一）为您提供智能股权价值服务所必须的功能</h4>
                        <p>1、注册成为用户</p>
                        <p>为完成创建账号，您需要提供个人验证身份的手机号码、电子邮箱地址。</p>
                        <p>2、使用线上产品</p>
                        <p>根据您企业自身的需求选择对应的线上产品与/或服务来填写所需服务应提交的企业信息。</p>
                        <p>3、支付功能</p>
                        <p>在您提交后，您可以选择第三方支付机构（包括微信支付及支付宝支付通道）所提供的支付服务。支付功能本身并不收集您的个人信息，但我们需要将您的交易金额信息与这些支付机构共享以实现其确认您的支付指令并完成支付。</p>
                        <p>4、交付产品与/或服务功能</p>
                        <p>在您在线完成支付后，我们通过后台审核，审核无误后一键生成对应的报告，您可通过登录注册账号浏览和下载该报告。</p>
                        <p>5、客服与售后功能</p>
                        <p>我们的估值客服和售后客服会使用您的个人信息和您填写的企业信息，对线上产品与/或服务填写的信息进行核实、辅导填写及后续的跟踪服务</p>
                        <h4>（二）开展内部数据分析和行业研究，改善我们的产品与/或服务</h4>
                        <p>我们通过对智能股权价值服务系统（SEVS）产品收集的企业数据进行行业分类研究，从而优化和改善我们的系统，以便为您提供更好的线上服务。</p>
                        <h4>二、我们如何使用Cookie和同类技术</h4>
                        <p>我们使用 Cookie 和类似技术来存储和遵守您的偏好和设置、使您能够登录。</p>
                        <p>您可根据自己的偏好管理或删除 Cookie。除了清除计算机上保存的所有 Cookie，大部分网络浏览器都设有阻止 Cookie 的功能。但如果您这么做，则需要在每一次访问我们的网站时更改用户设置。如需详细了解如何更改浏览器设置，请访问您使用的浏览器的相关设置页面。</p>
                        <h4>三、我们如何共享、转让、公开披露您的个人信息</h4>
                        <h4>（一）共享</h4>
                        <p>我们不会与心流以外的任何公司、组织和个人共享您的个人/企业信息，但以下情况除外：</p>
                        <p>1、事先获得您明确的同意或授权；</p>
                        <p>2、根据适用的法律法规、法律程序的要求、强制性的行政或司法要求所必须的情况下进行提供；</p>
                        <p>3、在法律法规允许的范围内，为维护心流、心流的关联方或合作伙伴、您或其他心流用户或社会公众利益、财产或安全免遭损害而有必要提供；</p>
                        <p>4、 只有共享您的个人/企业信息，才能实现我们的产品与/或服务的核心功能或提供您需要的服务；</p>
                        <p>5、应您需求为您处理您与他人的纠纷或争议。</p>
                        <h4>（二）转让</h4>
                        <p>我们不会将您的个人/企业信息转让给任何公司、组织和个人，但以下情况除外：</p>
                        <p>1、事先获得您明确的同意或授权；</p>
                        <p>2、根据适用的法律法规、法律程序的要求、强制性的行政或司法要求所必须的情况进行提供；</p>
                        <p>3、在涉及合并、收购、资产转让或类似的交易时，如涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织继续受本隐私政策的约束，否则，我们将要求该公司、组织重新征求您的授权同意。</p>
                        <h4>（三）公开披露</h4>
                        <p>我们仅会在以下情况下，公开披露您的个人/企业信息：</p>
                        <p>1、事先获得您明确的同意或授权；</p>
                        <p>2、根据法律、法规的要求、强制性的行政执法或司法要求所必须提供您个人信息的情况下，我们可能会依据所要求的个人信息类型和披露方式公开披露您的个人信息。在符合法律法规的前提下，当我们收到上述披露信息的请求时，我们会要求必须出具与之相应的法律文件，如传票或调查函。我们坚信，对于要求我们提供的信息，应该在法律允许的范围内尽可能保持透明。我们对所有的请求都进行了慎重的审查，以确保其具备合法依据，且仅限于执法部门因特定调查目的且有合法权利获取的数据。</p>
                        <p>四、我们如何保护和保存您的个人/企业信息</p>
                        <p>（一）我们已使用符合业界标准的安全防护措施保护您提供的个人/企业信息，防止数据遭到未经授权访问、公开披露、使用、修改、损坏或丢失。我们会采取一切合理可行的措施，保护您的个人/企业信息。例如，在您的浏览器与“服务器”之间交互数据时受SSL加密保护；我们同时对我们网站提供https安全浏览方式；我们会使用加密技术确保数据的保密性；我们会使用受信赖的保护机制防止数据遭到恶意攻击；我们会部署访问控制机制，确保只有授权人员才可访问个人/企业信息；以及我们会举办安全和隐私保护培训，加强员工对于保护个人/企业信息重要性的认识。</p>
                        <p>（二）我们会采取一切合理可行的措施，确保未收集无关的个人/企业信息。我们只会在达成本隐私政策所述目的所需的。</p>
                        <p>（三）互联网并非绝对安全的环境，而且电子邮件、即时通讯、社交软件等与其他用户的交流方式无法确定是否完全加密，我们建议您使用此类工具时请使用复杂密码，并注意保护您的个人信息安全。</p>
                        <p>（四）互联网环境并非百分之百安全，我们将尽力确保或担保您发送给我们的任何信息的安全性。如果我们的物理、技术或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改或毁坏，导致您的合法权益受损，我们将承担相应的法律责任。</p>
                        <p>（五）一旦发生个人信息安全事件，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们同时将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，当难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。</p>
                        <h4>五、您的权利</h4>
                        <p>按照中国相关的法律、法规、标准，以及其他国家、地区的通行做法，我们保障您对自己的个人/企业信息行使以下权利：</p>
                        <h4>（一）访问您的个人/企业信息</h4>
                        <p>您有权通过您的个人注册账号访问您前期填报的企业信息，法律法规规定的例外情况除外。</p>
                        <h4>（二）更正您的个人/企业信息</h4>
                        <p>当您发现您填报的企业信息错误时，您有权在我的报告页面选择相应的产品与/或服务项目自行修改，或当您提交使用的产品与/或服务信息被审核退回时，您可参考退回罗列的修改建议进行更正后再次提交。</p>
                        <h4>（三）删除您的个人/企业信息</h4>
                        <p>您在我的报告页面中可以直接删除未提交但已使用的产品与/或服务项目的企业信息。</p>
                        <p>在以下情形中，您可以向我们提出删除个人/企业信息的请求：</p>
                        <p>1、如果我们处理个人信息/企业信息的行为违反法律法规；</p>
                        <p>2、如果我们收集、使用您的个人/企业信息，却未征得您的同意；</p>
                        <p>3、如果我们处理个人信息/企业信息的行为违反了与您的约定；</p>
                        <p>4、如果我们终止服务及运营。</p>
                        <h4></h4>
                        <p>（四）响应您的请求</p>
                        <p>如果您无法通过上述方式访问、更正或删除您的企业信息，或您认为心流存在任何违反法律法规或与您关于个人/企业信息的收集或使用的约定，您均可以通过本隐私政策的联系方式与我们取得联系。为了保障安全，我们可能需要您提供书面请求，或提供您或填报企业的身份证明文件，我们将在收到您反馈并验证您身份后的15天内答复您的请求。对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情况收取一定的成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际的请求，我们可能会予以拒绝。</p>
                        <p>在以下情形下，按照法律法规要求，我们将无法响应您的请求：</p>
                        <p>1、与个人/企业信息控制者履行法律法规规定的义务相关的；</p>
                        <p>2、与刑事侦查、起诉、审批和执行判决等有关的；</p>
                        <p>3、有充分证据表明您存在主观恶意或滥用权力的；</p>
                        <p>4、出于维护个人/企业信息主体或财产等重大合法权益但又很难得到本人同意的；</p>
                        <p>5、响应您的请求将导致您或其他人、组织的合法权益受到严重损害的；</p>
                        <p>6、涉及商业机密的。</p>
                        <h4>六、本隐私政策如何更新</h4>
                        <p>我们可能适时会对本隐私政策进行调整或变更，本隐私政策的任何更新将以标注更新时间的方式公布在我们网站上，除法律法规或监管规定外，经调整或变更的内容一经通知或公布后生效。如您在隐私政策调整或变更后继续使用我们提供的任何产品与/或服务，我们相信这代表您已充分阅读、理解并接受修改后的隐私政策并受其约束。</p>
                        <p>七、如何联系我们</p>
                        <p>如果您对本隐私政策有任何疑问、意见或建议，通过以下方式与我们联系：</p>
                        <p>电话：010-84446599</p>
                        <p>一般情况下，我们将在15天内回复。如果您对我们的回复不满意，特别是我们的个人信息处理行为损害了您的合法权益，您还可以向网信、电信、公安及工商等监管部门进行投诉或举报。</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="logogFoot">
            <span>©2018心流网 
                <a target="_blank" href="https://beian.miit.gov.cn">京ICP备18036155号-2
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;增值电信业务经营许可证: 京B2-20182113</span>
                </a>
                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502037130">京公网安备 11010502037130号</a>
                </span>
            </span>
        </div>
    </div>
</template>
<script>
import qs from 'qs'
export default {
    name: "register",
    data() {
        return {
            register: {
                type: 2, // 手机2,邮箱1
                serviceType: 1,   // 寻求服务类型
                phoneNum: "", // 账号
                password: "", // 密码
                password2: "", // 确认密码
                pictureCode: "", // 图片验证码
                phoneCode: "",  // 手机验证码
                loginName: "", //电话,
                code:""  // 验证码
            },
            btnStatus: 0, // 注册状态
            phoneStatus: 0, // 账号提示类型
            pictureCodeStatus: 0, // 图片验证码提示类型
            passwordStatus: 0, // 密码提示类型
            passwordStatus_second: 0, // 密码提示类型
            uuid: "",  // 图片验证码id
            uuPicCode: "", // 图片验证码base64
            phoneCodeText: "发送验证码", // 发送验证码按钮text
            phoneNumCodeStatus: 0,
            clauseSure: 0,
            registration: 0,  // 心流协议
            disclaimer: 0,  // 免责声明
            confidentiality: 0, // 隐私政策
            active:0,
            checkStatus:false,
        };
    },
    mounted(){
        // let this_ = this;
        this.getPictureCode();
    },
    methods:{
        toIndex: function(){
            this.$router.push({
	        	name: 'home',
                path: '/home',
            })
        },
        // 跳转登录页
        toLogin: function(){
            this.$router.push({
                name:"login",
                path:"/login"
            })
        },
        toForgetPassword: function(){
            this.$router.push({
                name:"forgetPassword",
                path:"/forgetPassword"
            })
        },
        // 心流注册协议
        toRegistration: function(){
            this.$router.push({
                name:"registration",
                path:"/registration"
            })
        },
        // 免责声明
        toDisclaimer: function(){
            this.$router.push({
                name:"disclaimer",
                path:"/disclaimer"
            })
        },
        // 隐私政策
        toConfidentiality: function(){
            this.$router.push({
                name:"confidentiality",
                path:"/confidentiality"
            })
        },
        // 账号(手机号码)验证
        phoneVerify: function(){
            var this_ = this;
            var phoneReg = /^1[3|4|5|6|7|8|9]\d{9}$/; //电话
            if(!phoneReg.test(this_.register.loginName)){
                this_.phoneStatus = 1;
            }else if(phoneReg.test(this_.register.loginName)){
                var params = {};
                params.type = this_.register.type;
                params.loginName = this_.register.loginName;                
                this_.$post("flow/customer/checkCustomer",qs.stringify(params)).then((res) => {
                    console.log(res);
                    var data = res.data;
                    if(data){
                        if(data.code == 101){
                            this_.btnStatus = 0;
                            this_.phoneStatus = 2;
                            return false;
                        } else if(data.code == 103){
                            this_.btnStatus = 1;
                            this_.phoneStatus = 10;
                        };
                    };
                });
            };
        },
        // 获取图片验证码
        getPictureCode: function(){
            this.$get("flow/customer/getVerifiCode","").then((res) => {
                console.log(res)
                if(res.code == 200 && res.content){
                    this.uuid = res.content.uuid;
                    this.uuPicCode = res.content.image;
                }
            })
        },
        // 验证图片验证码
        pictureCodeVerify: function(){
            if(this.register.pictureCode){
                this.$get("flow/customer/checkVerifiCode",{
                    test: this.register.pictureCode,
                    uuid: this.uuid
                }).then((res) => {
                    console.log(res)
                    if(res.code != 200){
                        this.pictureCodeStatus = 1;
                    } else{
                        this.pictureCodeStatus = 10;
                    }
                })
            } else {
                this.pictureCodeStatus = 2;
            }
        },
        // 验证密码格式
        passwordVerify: function(){
            if(this.register.password){
                var passwordReg = /^[A-Za-z0-9@#$%]{6,16}$/; //密码
                if(!passwordReg.test(this.register.password)){
                    this.passwordStatus = 1;
                } else if(passwordReg.test(this.register.password)){
                    this.passwordStatus = 10;
                }
            } else {
                this.passwordStatus = 2;
            }
        },
        // 验证二次密码是否相同
        passwordVerify_Second: function(){
            if(this.register.password2){
                if(this.register.password !== this.register.password2){
                    this.passwordStatus_second = 1;
                } else {
                    this.passwordStatus_second = 10;
                };
            } else {
                this.passwordStatus_second = 2;
            }
        },
        // 倒计时
        countDown: function(time){
            var that = this;
            var times;
            if(time == 0){
                clearTimeout(times)
                that.phoneCodeText = "发送验证码";
            } else {
                that.phoneCodeText = time + "s";
                time--;
                times = setTimeout(function(){
                    that.countDown(time)
                },1000)
            }
        },
        // 发送验证码
        sendPhoneCode: function(){
            var this_  = this;
            if(this_.register.loginName && this_.phoneStatus == 10 && this_.register.pictureCode && this_.pictureCodeStatus == 10){
                var obj = {
                    phoneNum: this_.register.loginName,
                    uuid: this_.uuid,
                    verifiCode: this_.register.pictureCode
                };
                this_.$post("flow/customer/sendPhoneCode",qs.stringify(obj)).then((res) => {
                    console.log(res)
                    var data = res.data;
                    if(data.code == 200){
                        this_.countDown(60)
                    } else {
                        this_.countDown(3)
                        this_.phoneNumCodeStatus = 2
                    }    
                })
            } else if(!this_.register.loginName || this_.phoneStatus != 0){
                this_.phoneStatus = 1;
            } else if(!this_.register.pictureCode){
                this_.pictureCodeStatus = 2;
            } else if(this_.pictureCodeStatus != 0){
                this_.pictureCodeStatus = 1;
            } else {
                return false;
            }
        },
        // 验证码
        phoneCodeVerify: function(){
            if(this.register.code){
                this.phoneNumCodeStatus = 10;
            } else {
                this.phoneNumCodeStatus = 3;
            }
        },
        // 关闭窗口
        closeActive: function(){
            var this_ = this;
            this_.active = 0;
            this_.registration = 0;
            this_.disclaimer = 0;
            this_.confidentiality = 0;
        },
        // 注册
        registerCommit: function(){
            var this_ = this;
            console.log("subcommit");
            // 账号检索
            if(this_.phoneStatus != 10){
                if(!this_.register.loginName || this_.phoneStatus != 2){
                    this_.phoneStatus = 1;
                    // this_.$Message.info("请输入合法的手机号");
                    return
                } else {
                    // this_.$Message.info("该账号已注册");
                    return
                };
            };
            // 图片验证码检索
            if(this_.pictureCodeStatus != 10){
                if(!this_.register.pictureCode){
                    this_.pictureCodeStatus = 2;
                    // this_.$Message.info("请输入验证码");
                    return
                } else {
                    this_.pictureCodeStatus = 1;
                    // this_.$Message.info("验证码错误");
                    return
                }
            };
            // 密码检索
            if(this_.passwordStatus != 10){
                if(!this_.register.password){
                    this_.passwordStatus = 2;
                    // this_.$Message.info("请输入密码");
                    return
                } else {
                    this_.passwordStatus = 1;
                    // this_.$Message.info("密码格式有误");
                    return
                }
            }
            // 确认密码检索
            if(this_.passwordStatus_second != 10){
                if(!this_.register.password2){
                    this_.passwordStatus_second = 2;
                    // this_.$Message.info("请再次输入密码");
                    return
                } else {
                    this_.passwordStatus_second = 1;
                    // this_.$Message.info("两次输入的密码不一致，请重新输入");
                    return
                }
            };
            // 验证码非空判断
            if(!this_.register.code){
                if(this_.phoneNumCodeStatus == 2){
                    // this_.$Message.info("发送失败，请重新发送");
                    return
                } else {
                    this_.phoneNumCodeStatus = 3;
                    // this_.$Message.info("请输入验证码");
                    return
                }
            };
            // 协议/声明勾选判断
            if(!this_.checkStatus){
                this_.$Message.info('请勾选"我已阅读并同意《心流网注册协议》、《免责声明》和《隐私政策》"');
                return
            };
            this_.$post("flow/customer/registered",qs.stringify(this_.register)).then((res) => {
                console.log(res);
                var data = res.data;
                if(data.code == 200){
                    // this_.$Message.info("注册成功");
                    setTimeout(function(){
                        this_.$router.push({
                            name:"login",
                            path:"/login"
                        });
                    },1000);
                } else if(data.code == 104){
                    this_.phoneNumCodeStatus = 1;
                    // this_.$Message.info("验证码错误");
                }
            })
        }
    }
};
</script>
<style lang="less">
	::-webkit-input-placeholder {
		/* WebKit browsers，webkit内核浏览器 */
		color: #BFBFBF;
	}
	
	:-moz-placeholder {
		/* Mozilla Firefox 4 to 18 */
		color: #BFBFBF;
	}
	
	::-moz-placeholder {
		/* Mozilla Firefox 19+ */
		color: #BFBFBF;
	}
	
	:-ms-input-placeholder {
		/* Internet Explorer 10+ */
		color: #BFBFBF;
	}
	
	html,
	body,
	#app,
	#logo>div,
	.login-main {
		height: 100%;
	}
	
	.signIn {
		width: 100%;
		height: 100%;
		overflow: hidden;
		position: relative;
		background: url(../../assets/img/login/backgroundImage.jpg) no-repeat center center;
		background-size: cover;
	}
	
	.hide {
		display: none;
	}
	
	.signIn .router-link-active {
		margin-left: 20px;
	}
	
	.logogFoot {
		width: 100%;
		height: 80px;
		background: #fff;
		font-size: 14px;
		color: #585858;
		text-align: center;
		line-height: 80px;
		position: absolute;
		bottom: 0;
	}
	
	.logogFoot a {
		color: #585858;
		cursor: pointer;
	}
	
	/*注册开始*/
	.register {
		width: 1200px;
		height: 100%;
		max-height: calc(100% - 170px);
		margin: 0 auto;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		position: relative;
		top: 50%;
		transform: translateY(-50%);
		-webkit-transform: translateY(-50%);
		-moz-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		-o-transform: translateY(-50%);
	}
	
	@media screen and (max-height: 800px) {
		.register {
			width: 100%;
			overflow-y: scroll;
		}
	
		.register .registerContent {
			max-height: 100%;
			position: relative;
			right: calc((100% - 1150px) / 2);
		}
		.registerContent form {
		    background: #fff;
		    padding-bottom: 10px;
		}
	}
	
	.register .bd {
		position: fixed;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: -1;
	}
	
	.register .registerContent {
		width: 420px;
		height: 670px;
		background: rgba(255, 255, 255, 1);
		clear: both;
	}
	
	.register .registerTitle {
		width: 160px;
		height: 20px;
		font-size: 20px;
		color: rgba(65, 64, 63, 1);
		padding-top: 49px;
		margin: 0 auto;
		font-weight: bold;
	}
	
	.register .accountNumber {
		width: 84px;
		height: 14px;
		margin-top: 40px;
		margin-left: 296px;
	}
	
	.register .accountNumber span:first-child {
		color: #282828;
		font-size: 12px;
	}
	
	.register .accountNumber span:last-child {
		color: #4092ef;
		font-size: 12px;
		cursor: pointer;
	}
	
	.register .registerMethod {
		width: 100%;
		margin-top: 17px;
		border-bottom: 1px solid #bfbfbf;
	}
	
	.register .registerMethod span {
		display: inline-block;
		width: 56px;
		height: 12px;
		padding-bottom: 7px;
		text-align: center;
		cursor: pointer;
	}
	
	.register .registerMethod span:first-child {
		margin-left: 128px;
	}
	
	.register .registerMethod span.choose {
		border-bottom: 3px solid #4393ff;
	}
	
	.register .registerMethod span:last-child {
		margin-left: 89px;
	}
	
	.register ul {
		width: 300px;
		margin: 0 auto;
	}
	
	.register .phoneSele {
		display: inline-block;
		width: 116px;
	}
	
	.register .phoneSele .combo-select {
		border: none;
	}
	
	.register .phoneSele .combo-arrow:before {
		right: 5px;
	}
	
	.register .phoneSele .combo-arrow {
		width: 30px;
		height: 16px;
		margin-top: 7px;
		border-right: 1px solid #bfbfbf;
	}
	
	.register .phoneSele input {
		padding: 0;
		padding-right: 20px;
	}
	
	.register .ulClass .phone>input {
		padding-left: 10px;
		width: 167px;
		height: 30px;
	}
	
	.register .ulClass li {
		position: relative;
		height: 41px;
		line-height: 50px;
		border-bottom: 1px solid #bfbfbf;
	}
	
	.register .ulClass li .combo-dropdown li {
		width: 274px;
		height: 20px;
		line-height: 20px;
	}
	
	.register .ulClass .phone label {
		width: 86px;
		height: 15px;
		color: #555454;
		font-size: 16px;
	}
	
	.register .ulClass .phone input {
		color: #282828;
	}
	
	.register .ulClass li.verificationCode #code {
		width: 220px;
	}
	
	.register .ulClass li.verificationCode .sendCode {
		float: right;
		display: inline-block;
		width: 75px;
		cursor: pointer;
		margin-top: 8px;
		line-height: 30px;
		text-align: right;
	}
	
	.register .ulClass li.verificationCode .sendCode[disabled="disabled"] {
		color: #282828;
		background: rgba(255, 255, 255, 0);
	}
	
	.register .ulClass li.pictureVerification #pictureCode {
		width: 178px;
		box-shadow: inset 0 0 1000px #fff !important;
	}
	
	.register .ulClass li.pictureVerification>div {
		float: right;
		display: inline-block;
		width: 121px;
		height: 30px;
		cursor: pointer;
		/* margin-top: 11px;
	        line-height: 30px; */
		text-align: right;
	}
	
	.register .ulClass li.pictureVerification .picture {
		width: 79px;
		height: 30px;
		vertical-align: middle;
	}
	
	.register .ulClass li.pictureVerification .pictureCodeBtn {
		font-size: 12px;
		color: #1459F4;
	}
	
	.register .ulClass li input {
		font-size: 14px;
		width: 100%;
		box-shadow: inset 0 0 1000px #fff !important;
		height: 30px;
	}
	
	.register .format,
	.register .message {
		color: #e71f19;
		font-size: 12px;
		margin-top: 7px;
	}
	
	.register .format span,
	.register .message span {
		height: 12px;
		line-height: 13px;
		margin: 0 0 0 10px;
	}
	
	.register .message .returnAccount {
		color: #282828;
	}
	
	.register .suggest {
		height: 13px;
		margin-top: 7px;
	}
	
	.register .suggest i {
		display: inline-block;
		width: 16px;
		height: 16px;
		border: 1px solid #999;
		border-radius: 50%;
		text-align: center;
		line-height: 16px;
		color: #999;
		vertical-align: top;
	}
	
	.register .format i.remindIcon,
	.register .message i.remindIcon,
	.register .suggest i.remindIcon {
		display: inline-block;
		width: 16px;
		height: 16px;
		border: 1px solid #e71f19;
		border-radius: 50%;
		text-align: center;
		line-height: 16px;
		vertical-align: top;
		color: #e71f19;
	}
	
	.register .suggest span {
		color: #999;
		vertical-align: top;
		margin: 0 0 0 10px;
	}
	
	.register .messageBox {
		height: 20px;
	}
	
	.register .format.disNone,
	.register .message.disNone,
	.register .suggest.disNone,
	.register .choosePhone.disNone,
	.register .chooseEmail.disNone {
		display: none;
	}
	
	.register .lookAfter {
		margin-top: 12px;
		font-size: 0;
		width: 300px;
		height: 40px;
	}
	
	.register .lookAfter label {
		width: 33px;
		height: 28px;
		font-size: 14px;
		color: #999;
		margin-right: 8px;
		display: inline-block;
		line-height: 28px;
		float: left;
	}
	
	.register .lookAfter span:nth-child(2) {
		margin-left: 0;
	}
	
	.register .lookAfter span {
		width: 52px;
		height: 28px;
		font-size: 12px;
		margin-left: 12px;
		color: #999;
		border: 1px solid #bfbfbf;
		box-sizing: border-box;
		text-align: center;
		line-height: 28px;
		float: left;
		cursor: pointer;
	}
	
	.register .lookAfter span.sele {
		color: #fff;
		background: #4393ff;
		border: none;
	}
	
	.register .agree {
		clear: both;
		padding-top: 10px;
		font-size: 12px;
		width: 300px;
	}
	
	.register .agree img {
		cursor: pointer;
		width: 10px;
		height: 10px;
		margin: 5px 6px 0 0;
		float: left;
	}
	
	.register .agree img.active {
		display: inline-block;
	}
	
	.register .agree label {
		color: #bfbfbf;
		line-height: 20px;
		text-align: justify;
	}
	
	.register .agree label span {
		color: #1459F4;
		/* vertical-align: top; */
		margin-top: -2px;
		cursor: pointer;
	}
	
	.register .btn {
		margin-top: 20px;
		width: 300px;
		height: 50px;
		background: #1459F4;
		color: #fff;
		text-align: center;
		line-height: 50px;
		font-size: 16px;
		cursor: pointer;
	}
	
	/*注册结束*/
	
	.myModa .myAlertBox p {
		padding: 20px;
		line-height: 26px;
		font-size: 14px;
		color: #808080;
		text-align: center;
	}
	
	/*协议和声明*/
	.active_Bg {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.5);
		z-index: 22;
	}
	
	.agreement {
		position: fixed;
		width: 790px;
		height: 370px;
		margin: -185px 0 0 -395px;
		top: 50%;
		left: 50%;
	}
	
	.img_bg {
		position: absolute;
	}
	
	.text_content {
		position: relative;
		top: 10px;
	}
	
	.logo_title {
		display: block;
		width: 120px;
		margin: 0 auto;
		margin-top: 10px;
	}
	
	.close {
		display: block;
		position: absolute;
		width: 12px;
		height: 12px;
		right: 34px;
		top: 15px;
		cursor: pointer;
	}
	
	.text_content>p {
		margin-top: 19px;
		text-align: center;
		font-size: 14px;
		color: rgba(40, 40, 40, 1);
		line-height: 14px;
		font-weight: bold;
	}
	
	.agreementContent {
		width: 716px;
		height: 230px;
		margin: 0 auto;
		margin-top: 14px;
		background: rgba(241, 241, 241, 1);
		border-radius: 6px;
		padding: 15px 20px;
		overflow-y: scroll;
		font-size: 12px;
		color: rgba(40, 40, 40, 1);
		line-height: 24px;
		text-align: justify;
	}
	
	.agreementContent h4 {
		text-indent: 2em;
	}
	
	.agreementContent p {
		text-indent: 2em;
		text-align: justify;
	}
	
	.myModa .myAlertBox>div.alertBox>p {
		float: left;
		width: 168px;
		font-size: 14px;
		line-height: 40px;
		color: #000;
	}
	
	/* 绑定手机号码框 */
	.telephoneNumbind {
		width: 406px;
		height: 498px;
		background: #ffffff;
		float: right;
		margin: 200px 0 0 0;
	}
	
	.telephoneNumbind h3 {
		/* width: 136px; */
		height: 23px;
		font-size: 22px;
		font-weight: bold;
		color: #41403f;
		line-height: 100%;
		margin: 55px 0 0 0;
		text-align: center;
	}
	
	.telephoneNumber,
	.pictureCode,
	.telephoneNumberTureCode {
		height: 50px;
		margin: 27px 53px 0;
		border-bottom: 1px solid #ccc;
		position: relative;
	}
	
	.telephoneNumber input {
		border: none;
		height: 50px;
		/* border-bottom: 1px solid #ccc; */
		width: 100%;
		font-size: 14px;
		line-height: 50px;
	}
	
	.telephoneNumber p {
		font-size: 14px;
		color: #f00;
		position: absolute;
		top: 56px;
		left: 0;
	}
	
	.pictureCode input {
		border: none;
		height: 50px;
		width: 50%;
		float: left;
		font-size: 14px;
		line-height: 48px;
		vertical-align: middle;
	}
	
	.pictureCode img {
		float: left;
		width: 79px;
		height: 30px;
		vertical-align: middle;
		margin: 10px 0 0 0;
	}
	
	.pictureCode p {
		float: right;
		color: #1459F4;
		font-size: 14px;
		line-height: 50px;
		cursor: pointer;
	}
	
	.pictureCode span {
		color: #f00;
		position: absolute;
		top: 60px;
		left: 0;
		font-size: 14px;
	}
	
	.telephoneNumberTureCode input {
		border: none;
		height: 50px;
		/* border-bottom: 1px solid #ccc; */
		width: 60%;
		float: left;
		line-height: 48px;
		font-size: 14px;
	}
	
	.telephoneNumberTureCode button {
		border: none;
		height: 33px;
		/* border-bottom: 1px solid #ccc; */
		width: 110px;
		float: right;
		background: #1459F4;
		margin: 8px 0;
		color: #fff;
		text-align: center;
		font-size: 14px;
		font-weight: 400;
		cursor: pointer;
	}
	
	.telephoneNumberTureCode p {
		color: #f00;
		font-size: 14px;
		position: absolute;
		top: 60px;
		left: 0;
	}
	
	.telephoneNumbind>button {
		width: 300px;
		height: 50px;
		background: #1459F4;
		text-align: center;
		color: #fff;
		font-size: 16px;
		font-weight: 400;
		margin: 44px 53px 0;
		cursor: pointer;
	}
	
	.telephoneNumbind>p {
		height: 15px;
		font-size: 14px;
		font-weight: bold;
		line-height: 86px;
		text-align: right;
		margin: 0 53px 0 0;
	}
	
	.telephoneNumbind p a {
		color: #1459F4;
	}
	.pc_register{
		height: 100%;
	}
	.mobile_register {
		display: none;
	}
	
	.mobile_header {
		display: none;
	}
	
	@media screen and (max-width: 750px) {
		#app.signIn {
			margin-top: 0;
			background: #fff;
		}
	
		header {
			display: none !important;
		}
	
		.logogFoot {
			display: none;
		}
	
		.pc_register {
			display: none;
		}
	
		.mobile_register {
			display: block;
			padding: 0 20px;
			margin: 30px 0 0 0;
		}
	
		.mobile_register>h3 {
			font-size: 20PX;
			line-height: 60px;
			font-weight: 800;
			text-align: center;
		}
	
		.mobile_header {
			display: block;
			height: 76px;
			background: #f7f8fa;
			border-bottom: 1px solid #e5e5e5;
		}
	
		.mobile_header img {
			width: 180px;
			margin: 16px 0 0 20px
		}
	
		.mobile_header button {
			background: transparent;
			font-size: 14PX;
			line-height: 1;
			margin: 32px 20px 0 0;
			color: #1459F4;
			float: right;
		}
	
		.mobile_register .infoItem {
			width: 100%;
			height: 80px;
			margin: 30px 0 0;
		}
	
		.mobile_register .infoItem input {
			width: 100%;
			height: 60px;
			border: 1px solid #ddd;
			font-size: 14PX;
			line-height: 1;
			text-indent: 14px;
			color: #000;
		}
	
		.mobile_register .phoneNumItem .phoneSele {
			border: 1px solid #ddd;
		}
	
		.mobile_register .phoneNumItem input {
			width: 62%;
			margin-left: 3%;
			/* float: left; */
			border: none;
		}
	
		.mobile_register .pictureCodeItem img {
			height: 60px;
			margin-right: 3%;
			/* float: left; */
			display: inline-block;
		}
	
		.mobile_register .pictureCodeItem input {
			width: 45%;
			margin-right: 5%;
			float: left;
			display: inline-block;
		}
	
		.mobile_register .codeItem input {
			width: 55%;
			margin-right: 5%;
			/* float: left; */
			display: inline-block;
		}
	
		.mobile_register .codeItem input#sendCode {
			width: 40%;
			margin: 0;
			text-indent: 0;
			text-align: center;
		}
	
		.mobile_register .codeItem button {
			width: 45%;
			/* float: left; */
			display: inline-block;
			border: 1px solid #1459F4;
			color: #1459F4;
			height: 60px;
			line-height: 60px;
			text-align: center;
			background: #fff;
		}
	
		.mobile_register .pictureCodeItem .changeOne {
			width: 18%;
			float: right;
			margin: 20px 0 0 0;
			font-size: 14PX;
			display: inline-block;
			color: #1459F4;
			cursor: pointer;
			text-align: center;
		}
	
		.mobile_register .phoneNumItem select {
			width: 30%;
			height: 60px;
			float: left;
			margin: 0 0 0 2%;
			background: transparent !important;
	
		}
	
		.mobile_register .infoItem .messageBox {
			height: 25px;
			width: 100%;
			margin: 10px 0 0 0;
		}
	
		.mobile_register .infoItem .messageBox span {
			font-size: 14px;
			line-height: 20px;
			color: #999;
			margin: 0 0 0 10px;
		}
	
		.mobile_register .infoItem .messageBox i {
			width: 20px;
			height: 20px;
			border: 0.5px solid #999;
			border-radius: 50%;
			color: #999;
			line-height: 20px;
			text-align: center;
			font-size: 14px;
			margin: -3px 0 0 0;
		}
	
		.mobile_register .infoItem .messageBox .format i,
		.mobile_register .infoItem .messageBox .message i {
			color: #e71f19;
			border-color: #e71f19;
		}
	
		.mobile_register .infoItem .messageBox .format span,
		.mobile_register .infoItem .messageBox .message span {
			color: #e71f19;
		}
	
		.mobile_register .lookAfter {
			margin: 30px 0 0 0;
		}
	
		.mobile_register .lookAfter>span {
			width: 100px;
			height: 40px;
			font-size: 16px;
			margin-left: 12px;
			color: #999;
			border: 1px solid #BFBFBF;
			box-sizing: border-box;
			text-align: center;
			line-height: 40px;
			/* float: left; */
			cursor: pointer;
		}
	
		.mobile_register .lookAfter>span.sele {
			color: #fff;
			background: #1459F4;
			border-color: #1459F4;
		}
	
		.mobile_register .lookAfter label {
			width: 50px;
			height: 40px;
			font-size: 16px;
			color: #999;
			margin-right: 8px;
			display: inline-block;
			line-height: 40px;
			float: left;
		}
	
		.mobile_register .agree {
			margin: 20px 0 0 0;
		}
	
		.mobile_register .agree>label {
			text-align: justify;
			font-size: 18px;
			line-height: 36px;
		}
	
		.mobile_register .agree>label span {
			text-align: justify;
			margin-top: -4px;
			color: #1459F4;
			cursor: pointer;
		}
	
		.mobile_register .agree>img {
			width: 24px;
			height: 24px;
			margin: 4px 16px 0 0;
			float: left;
		}
	
		.mobile_register .btn {
			width: 100%;
			height: 70px;
			background: #1459F4;
			color: #fff;
			text-align: center;
			line-height: 70px;
			font-size: 14PX;
			margin: 15px 0 0 0;
		}
	}
</style>