<template>
	<div class="formMain">
	  
	  <div class="formBodyPositioning">
		<Header />
		<div class="formWidth">
		  <div class="formMainModule">
			<div class="formInfomationTitle">
			  软性指标
			</div>
		   <!-- 导航栏-->
			<div id="soft_type">
				<div :class="['float-box']">
					<ul>
						
						<li :class="{active:i==current}" v-for="(item, i) in questionBank" @click="indicator(i)"
							ref="guideLis">
							<a :href="`#${item.contentType}`">{{item.contentName}}</a>
						</li>
					</ul>
					<!-- 完成度-->
					<div class="completion">
						<span>已完成</span>
						<span><em id="num">{{selected}}</em><em>/</em><em id="total">{{total}}</em></span>
					</div>
				</div>
			</div>
			<div class="relevantQuestionMain" ref="xqBox" style="height:75%" id="xqBox">
			  <div class="relevantQuestion">
				<div id="softInformation">
					<div>
						<!-- 软性指标主要内容-->
						<div class="soft-container">
							<!-- 软性指标：题目内容-->
							<div class="soft-main" :style="obj2">
								<div v-for="(item, i) in questionBank" class="soft-sort" :id="item.contentType" :style="obj"
									ref="softSort">
									<p class="sort-tit"> <span></span> <span>{{item.contentName}}</span> </p>
									<div class="section">
										<div v-for="(topic, t) in item.contentDetails" class="row" data-required="1" id="">
											<div :class="['position-tit-container',noChooseRed?'no-choose-red':'']">
												<span>{{topic.contval}}</span> <span class="postion-tit">{{topic.content}}</span>
												<div class="desc-container" v-if="topic.canExplain!=false">
													<i class="explain">?</i>
													<div class="desc-main">
														<span></span>
														<p>{{topic.explaination}}</p>
													</div>
												</div>
											</div>
											<div class="options-container">
												<ul>
													<li  :ref="answer.chooseFlag==1?'active':''"
														:class="answer.chooseFlag==1?'active':''"
														v-for="(answer, a) in topic.options"
														@click.stop.prevent="selectTopic(i,t,a,answer,$event)">
														<input type="radio" :checked="answer.chooseFlag=='1'?'checked':''"
															:id="answer.id" :name="answer.questionId" :data-type="item.contentType">
														<label :for="answer.id">{{answer.content}}</label>
														<div class="desc-container" v-if="answer.canExplain!=false">
															<i class="explain">?</i>
															<div class="desc-main">
																<span></span>
																<p>{{answer.explaination}}</p>
															</div>
														</div>
													</li>

												</ul>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="saveBox">
							<span class="closePage" id="saveButton"  @click="closeStep">关闭</span>
							<button class="save" id="saveButton" data-isClick="false" @click="saveButton" v-preventReClick>保 存</button>
						</div>
					</div>
					<!-- <Modal v-model="myAlert" class-name="vertical-center-modal" :mask-closable="false">
						<img class='warning' src="../../assets/img/remind-01@2x.png">
						<span>您有必填项未填写，请填写完成后再保存</span>
						<div slot="footer" class="ivu-sure" @click="submitDialog">确定</div>
					</Modal> -->
					<dialogBar v-model="myAlert" :type="popButonType" :title='markedWords' content="" 
					@confirmJump="clickConfirmJump()" 
					@whyem="clickwhyem()"
					@cacheTrueMask="clickCacheTrueMaskJump()"  
      				@cacheMask="clickCacheMaskJump()"
					></dialogBar>

					<router-view />
				</div>
				</div>
			</div>
		</div>
	</div>
</div>
</div>
</template>
<script>
	// 头部组件引入
	import Header from '../../common/header/header.vue';
	// 尾部组件引入
	import Footer from '../../common/footer/footer.vue';
	import qs from 'qs'
	import axios from 'axios';
	import {
		mapState,
		mapMutations
	} from 'vuex';
	import '../../assets/css/softIndex.css';
	import dialogBar from './popue.vue'
	import $ from 'jquery'

	export default {
		data() {
			return {
				current: 0, // 当前选中的是哪个指标
				questionBank: [],
				contentDetails: [],
				selected: 0, //当前选的几道题
				total: 0, //总题数
				scrollTop: 0, //滚动条距离顶部的距离
				obj: {
					marginTop: 0,
					paddingTop: 40
				},
				obj2: {
					paddingTop: 0
				},
				noChooseRed: false,
				myAlert: false,
				isClick: false,
				projectName:'',
				versions:2,
				markedWords:"",
				popButonType:""
			}
		},
		components: {
			Header,
			Footer,
			dialogBar
		},
		mounted() {
			if (this.$route.query.type == 12) {
				this.projectName = this.domain.turingProject;
			}
			this.pageModify('false');
			this.getQuestion();
		},
		updated() {
			if (this.$refs.active) {
				this.selected = this.$refs.active.length;
			}
			// 判断需不需要缓存
			// console.log(this.selected)
			// console.log(this.total)
			if(this.selected != this.total){
				// 是缓存
				this.cacheOrNot = '0'
			}
		},
		computed: {
			...mapState(['channel', 'reportId', 'currentPageModify', 'currentPageSave']),
			// 在计算属性获取到这个xqBox的dom元素
			xqBox() {
				return document.querySelector("#xqBox");
			}
		},
		watch: {
			
		},
		methods: {
			...mapMutations(['pageModify']),
			getQuestion: function(close) {
				// console.log("21")
				var url = this.projectName + 'question/getQuestion';
				this.$post(url, qs.stringify({
						stage: this.$route.query.stage,
						reportType: this.$route.query.type,
						reportId: this.$route.query.reportId ? this.$route.query.reportId : '',
						version: this.versions
					}))
					.then((res) => {
						this.questionBank = res.data.content;

						// 缓存数据逻辑====================================

						


						this.isCache = res.data.content[0].contentDetails[0].options[0].isCache
						if(res.data.content[0].contentDetails[0].options[0].isCache == "0" && !sessionStorage.isHistoryCache){
							this.myAlert = true;
							this.popButonType = 'cacheType';
							this.markedWords = '您上次填写过估值报告，是否载入缓存信息？';

							// 默认不选中
							for (var i = 0; i < res.data.content.length; i++) {
									for (var k = 0; k < res.data.content[i].contentDetails.length; k++) {
										for (var j = 0; j < res.data.content[i].contentDetails[k].options.length; j++) {
												res.data.content[i].contentDetails[k].options[j].chooseFlag = '0'
										}
									}	
							}
						}

						// 关闭  调用清除缓存
						if(res.data.content[0].contentDetails[0].options[0].isCache  == '0' && close=='0'){
							this.method.clearCaching(this.$route.query.type,this.$route.query.stage,this)
						}
						// 缓存数据逻辑====================================

						// 总条数
						this.total = 0;
						for (var i = 0; i < this.questionBank.length; i++) {
							this.total += this.questionBank[i].contentDetails.length;
						}

						
						
						// 循环条数
						var cont = 0
						for (var i = 0; i < res.data.content.length; i++) {
								for (var k = 0; k < res.data.content[i].contentDetails.length; k++) {
										this.$set(res.data.content[i].contentDetails[k], 'contval', cont+=1)
								}	
                        }
						this.xqBox.addEventListener("scroll", this.handleScroll); // 监听滚动事件，然后用handleScroll这个方法进行相应的处理
					});

			},
			indicator: function(i, contentType) {
				console.log(i)
				// this.current = i;
			},
			selectTopic: function(i, t, a, item, e) {
				this.pageModify('true');
				e.currentTarget.parentElement.parentElement.previousElementSibling.className =
					"position-tit-container";
				var selectTopic1 = this.questionBank[i].contentDetails[t].options
				for (var j = 0; j < selectTopic1.length; j++) {
					if (j == a) {
						this.$set(this.questionBank[i].contentDetails[t].options[j], 'chooseFlag', 1)
					} else {
						this.$set(this.questionBank[i].contentDetails[t].options[j], 'chooseFlag', 3)
					}
				}
				
				// 缓存 ======================
				this.$nextTick(() => {
					var positionJson = ''; // 答案的json
					$(".options-container li>input[type='radio']:checked:not('.table_radio')").each(function() {
							positionJson = positionJson + "{'optionId':'" + $(this).attr("id") + "'," +
							"'questionId':'" + $(this).attr("name") + "'," + "'contentType':'" + $(this)
							.attr(
								"data-type") + "'},";
					});
					positionJson = "[" + positionJson.substring(0, positionJson.length - 1) + "]";
					//调用缓存共用方法
					// console.log(this.cacheOrNot)
					if(this.cacheOrNot == '0'||this.isCache == '0'){
						this.method.caching("softJArray",positionJson,this.$route.query.type,this.$route.query.stage,this)
					}
				})
				// 缓存 =======================
			},
			
			handleScroll: function() {
				var scrollTop = this.xqBox.scrollTop;
				this.scrollTop = scrollTop;
				var num = [];
				var $titles = this.$refs.softSort;
				var $guideLis = this.$refs.guideLis;
				for (var i = 0; i < $titles.length; i++) {
					var $currEle = $titles[i];
					if (scrollTop >= $currEle.offsetTop && scrollTop <= $currEle.offsetTop + $currEle.offsetHeight) {
						num.push(i);
					}
				}
				if (num.length > 0) {
					this.current = num[num.length - 1];
				}
			},
			
			saveButton: function() {
				
					if (this.selected < this.total) {
						this.popButonType = 'whyEmpty';
						this.markedWords = '您有必填项未填写，请填写完成后再保存'
						this.myAlert = true;
					} else {
						var positionJson = ''; // 答案的json
						var params = {};
						var url = '';

						$(".options-container li>input[type='radio']:checked:not('.table_radio')").each(function() {
							positionJson = positionJson + "{'optionId':'" + $(this).attr("id") + "'," +
								"'questionId':'" + $(this).attr("name") + "'," + "'contentType':'" + $(this)
								.attr(
									"data-type") + "'},";
						});
						positionJson = "[" + positionJson.substring(0, positionJson.length - 1) + "]";

						params = {
							stage: this.$route.query.stage,
							softJArray: positionJson,
							reportId: this.$route.query.reportId ? this.$route.query.reportId : '',
							reportType:this.$route.query.type,
							version:this.versions
						}
						url = this.projectName + 'question/saveSoftIndex';
						if (!this.isClick) {
							this.isClick = true; //已保存信息
							this.$post(url, qs.stringify(params))
								.then((res) => {
									
										this.$router.push({
											path: '/home',
											query: {
												stage: this.$route.query.stage,
												type: this.$route.query.type,
												reportId: res.data.content
											}
										})
								});
						}
					}
				

			},
			closeStep: function() {
				this.myAlert = true;
				this.popButonType = 'closeData';
				this.markedWords = '当前数据尚未保存，是否确定离开？';
			},
			// 关闭弹出层  点击确定回调
			clickConfirmJump(){
				// console.log('跳转首页');
				this.getQuestion('0');
				this.$router.push({
					path: '/home',
					query: {
						stage: this.$route.query.stage,
						type: this.$route.query.type,
						reportId: this.$route.query.reportId,
					}
				})
			},

			//是  载入缓存
			clickCacheTrueMaskJump(){
				sessionStorage.isHistoryCache = 1;
				this.getQuestion();
			},

			//否  载入缓存
			clickCacheMaskJump(){
				sessionStorage.isHistoryCache = 1;
				this.method.clearCaching(this.$route.query.type,this.$route.query.stage,this,'getQuestion')
			},
			// 保存  弹出提示框遮罩   确定后滚动条回滚到问题位置
			clickwhyem(){
				this.myAlert = false;	
				var refxqBox = document.querySelector("#xqBox")
				$(".row").each(function() {
					if ($(this).find(".options-container li>:radio:checked").length <= 0) {
						var radioId = $(this).find(".options-container li>:radio:first").attr("id");
						refxqBox.scrollTop = $(this).find(".options-container")[0].offsetTop-"140";
						$("#" + radioId).parents(".options-container").siblings(".position-tit-container")
							.addClass("no-choose-red");
						return false;
					}
				})
			}

		}


	}
</script>