<template>
    <div class="dialog" v-show="showMask">
        <div class="dialog-container-cache" v-if="type == 'cacheType'">
                <!-- 是否需载入缓存遮罩层 -->
                <img src="../../assets/img/filledTuring/lingdang.png" class="dia-img-em">
                <p class="dialog-title">{{title}}</p>
                <div class="close-btn" @click="cacheMask">
                    <img src="../../assets/img/filledTuring/cguanbi.png" alt="关闭遮罩层-心流">
                </div>
                <div  class="cache-save-branch-bg"  @click="cacheTrueMask">
                        是
                </div>
                <div  class="cache-save-branch"  @click="cacheMask">
                        否
                </div>
        </div>
        <div class="dialog-container" v-else>
            <img v-if="type == 'notEmpty'||type == 'notEmpty-info' ||type == 'whyEmpty'||type == 'delType'" src="../../assets/img/filledTuring/提示.png" class="dia-img-em">
            <img v-else src="../../assets/img/filledTuring/tishitanhchuang.png" class="dia-img">

            <p class="dialog-title">{{title}}</p>
            <!-- 数据为空时候  弹出提示层  确定-->
            <div v-if="type == 'notEmpty'" class="dia-save">
                    <div @click="closeMask">
                        确定
                    </div>
            </div>

            <!-- 数据为空时候  弹出提示层  确定-->
            <div v-if="type == 'notEmpty-info'" class="dia-save">
                    <div @click="closeMaskInfo">
                        确定
                    </div>
            </div>
            
            <!-- 填写 关闭 弹出提示框   确定、取消-->
            <div v-if="type == 'closeData'" class="dia-close">
                    <div  class="dia-save-branch-bg" @click="closeMask">
                        取消
                    </div>
                    <div  class="dia-save-branch" @click="confirmJump">
                        确定
                    </div>
            </div>

            <!-- 软项指标   未填写没填写 -->
            <div v-if="type == 'whyEmpty'" class="dia-save">
                    <div @click="whyem">
                        确定
                    </div>
            </div>


             <!-- 列表 弹出提示框   确定、取消-->
             <div v-if="type == 'delType'" class="dia-close">
                    <div  class="dia-save-branch-bg" @click="closeMask">
                        取消
                    </div>
                    <div  class="dia-save-branch" @click="listConfirmJump">
                        确定
                    </div>
            </div>

            <!-- 一键生成报告  提示 -->
            <div v-if="type == 'generateBtnType'" class="dia-save">
                    <div @click="closeMask">
                        确定
                    </div>
            </div>

           
            <div class="close-btn" @click="closeMask">
                <img src="../../assets/img/filledTuring/guanbi.png" alt="关闭遮罩层-心流">
            </div>
        </div>
        
</div>
</template>
<script>
export default {
    props: {
        value: {},
        type:{
            type: "",
            default: 'default'
        },
        content: {
            type: String,
            default: ''
        },
        title: {
            type: String,
            default: ''
        },
    },
    data(){
        return{
            showMask: false,
        }
    },
    methods:{
        closeMask(){
            this.showMask = false;
        },
        closeMaskInfo(){
            this.$emit('closeMaskInfoJump');
            this.closeMask();
        },
        // 关闭弹出层
        confirmJump(){
            this.$emit('confirmJump');
            this.closeMask();
        },
        whyem(){
            this.$emit('whyem');
            this.closeMask();
        },
        listConfirmJump(){
            this.$emit('delTypeJump');
            this.closeMask();
        },
        cacheTrueMask(){
            this.$emit('cacheTrueMask');
            this.closeMask();
        },
        cacheMask(){
            this.$emit('cacheMask');
            this.closeMask();
        }

    },
    mounted(){
        this.showMask = this.value;
        // console.log(this.value)
    },
    watch:{
        value(newVal, oldVal){
            this.showMask = newVal;
        },
        showMask(val) {
            this.$emit('input', val);
        }
    },
}
</script>
<style lang="less" scoped>
    .dialog{
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.6);
        z-index: 9999;
    }
    .dialog-container{
            width: 500px;
            height: 300px;
            background: #ffffff;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border-radius: 8px;
            position: relative;
            background: url("../../assets/img/filledTuring/tishi.png");
            background-size: 100% 100%;
    }
    .dialog-container-cache{
            width: 500px;
            height: 320px;
            background: #ffffff;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border-radius: 8px;
            position: relative;
            background: url("../../assets/img/filledTuring/cach.png");
            background-size: 100% 100%;
    }
    .dialog-container .dia-img{
        width: 130px;
        display: block;
        margin: auto;
        padding-top: 45px;
    }
    .dia-img-em{
        width: 100px;
        display: block;
        margin: auto;
        padding-top: 60px;
    }
    .dialog-title{
        font-size: 14px;
        color: #fff;
        text-align: center;
        margin-top: 20px;
    }
    .dia-save{
        width:150px;
        height: 60px;
        background: url("../../assets/img/filledTuring/baocun.png");
        background-size: 100% 100%;
        text-align:center;
        line-height: 60px;
        color: #fff;
        font-size: 14px;
        margin: auto;
        cursor: pointer;
    }
    .dia-close{
        // width: 100%;
        overflow: hidden;
        margin-left: 110px;
    }
    .dia-save-branch{
        width:150px;
        height: 60px;
        background: url("../../assets/img/filledTuring/baocun.png");
        background-size: 100% 100%;
        text-align:center;
        line-height: 60px;
        color: #fff;
        font-size: 14px;
        margin: auto;
        float: left;
        cursor: pointer;
       
    }
    .dia-save-branch-bg{
        width:120px;
        height: 30px;
        background: url("../../assets/img/filledTuring/组 20@2x.png");
        background-size: 100% 100%;
        text-align:center;
        line-height: 30px;
        color: #fff;
        font-size: 14px;
        margin: auto;
        float: left;
        cursor: pointer;
        margin-top: 15px;
        margin-right: 30px;
    }
    .cache-save-branch{
        width:150px;
        height: 60px;
        background: url("../../assets/img/filledTuring/shi.png");
        background-size: 100% 100%;
        text-align:center;
        line-height: 60px;
        color: #fff;
        font-size: 14px;
        margin: auto;
        float: left;
        cursor: pointer;
        // margin-left: 20px;
    }
    .cache-save-branch-bg{
        width:140px;
        height: 60px;
        background: url("../../assets/img/filledTuring/fou.png");
        background-size: 100% 100%;
        text-align:center;
        line-height: 60px;
        color: #fff;
        font-size: 14px;
        // margin: auto;
        float: left;
        cursor: pointer;
        // margin-top: 15px;
        margin-left: 100px;
    }
    .close-btn{
        width: 40px;
        height: 40px;
        position: absolute;
        right: 0px;
        top: 0px;
        cursor: pointer;
    }
    .close-btn img{
        width: 100%;
    }
</style>
