<template>
	<div class="formMain">
		<div class="formBodyPositioning">
			<Header />
			<div class="formWidth">
				<div class="formMainModule">
					<div class="formInfomationTitle">估值报告</div>
					<PaymentInformation />
					<div class="relevantQuestionMain">
						<Navigation :nav="navList" />
						<div class="auditContain">
							<img src="../../assets/img/getInfo/auditIcon.png" alt="审核图标-心流">
							<div :style="{top:(auditStatus==0?'50px':'0')}">
								<p v-if="auditStatus==1" style="font-size: 18px;">您的估值报告审核已通过!</p>
								<p v-if="auditStatus==2" style="color:#feff6a;font-size: 18px;">您的估值报告审核中，请耐心等待！</p>
								<p v-if="auditStatus==0" style="color: #FF0000;font-size: 18px;">您的估值报告审核未通过！</p>
								<p v-if="auditStatus==0" style="color: #00E4FF;">您可修改信息，重新提交审核</p>
								<p>如有疑问请拨打心流客服电话：+86(10)8444-6599</p>
								<button v-if="auditStatus==0" @click="modification">立即修改</button>
							</div>
						</div>
						<div v-if="auditStatus==0" class="reasonsFailure">
							<pre>审核未通过原因：{{auditConent}}</pre>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>


<script>
	// 头部组件引入
	import Header from '../../common/header/header.vue';
	// 尾部组件引入
	import Footer from '../../common/footer/footer.vue';
	import '../../assets/css/common.css';
	import PaymentInformation from '../../common/getInfo/paymentInformation.vue';
	import Navigation from '../../common/getInfo/navigation.vue';
	import qs from 'qs'

	export default {
		data() {
			return {
				navList: ['资产负债表', '利润表', '审核状态', '基本信息', '软性指标'],
				projectName: '',
				auditStatus: this.$route.query.auditStatus ? this.$route.query.auditStatus : '',
				auditConent: ''
			}
		},
		components: {
			Header,
			Footer,
			PaymentInformation,
			Navigation
		},
		mounted() {
			if (this.$route.query.type == 12) {
				this.projectName = this.domain.turingProject;
			}
			this.init()

		},
		methods: {
			init: function() {
				var url = this.projectName + 'auditInfo/getAuditNotPassInfo';
				this.$post(url, qs.stringify({
						reportId: this.$route.query.reportId ? this.$route.query.reportId : ''
					}))
					.then((res) => {
						console.log(res.data)
						this.auditConent = res.data.content.auditConent;
					});
			},
			modification: function() {
				this.$router.push({
					path: '/home',
					query: {
						stage: this.$route.query.stage,
						type: this.$route.query.type,
						reportId: this.$route.query.reportId
					}
				})
			}
		}
	}
</script>

<style scoped>
	.relevantQuestionMain {
		height: 79%;
		margin-top: 3%;
	}

	.auditContain {
		text-align: center;
		margin-top: 9%;
	}

	.auditContain img {
		margin-right: 40px;
		vertical-align: middle;
	}

	.auditContain>div {
		height: 145px;
		display: inline-block;
		text-align: left;
		line-height: 29px;
		position: relative;
	}

	.auditContain>div p {
		font-size: 14px;
		color: #fff;
	}

	.auditContain button {
		width: 83px;
		height: 31px;
		line-height: 28px;
		border: 2px solid #00E4FF;
		border-radius: 4px;
		text-align: center;
		color: #00E4FF;
		background: #070088;
		margin-top: 23px;
		margin-left: 93px;
		cursor: pointer;
	}

	.reasonsFailure {
		width: 84%;
		margin: 80px auto 0;
		text-align: justify;
		font-size: 14px;
		color: #fff;
	}

	pre {
		white-space: pre-wrap;
		word-wrap: break-word;
	}
	@media screen and (max-height:800px){
		.relevantQuestionMain{
			margin-top: 5%;
		}
	}
</style>
