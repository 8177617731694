<template>
	<div class="formMain">
		<div class="formBodyPositioning">
			<Header />
			<div class="formWidth">
				<div class="formMainModule">
					<div class="formInfomationTitle">估值报告</div>
					<PaymentInformation />
					<div id="xqBox" class="relevantQuestionMain">
						<Navigation :nav="navList" />
						<div class="relevantQuestion">
							<div id="softInformation">
								<div>
									<!-- 软性指标主要内容-->
									<div class="soft-container">
										<!-- 软性指标：题目内容-->
										<div class="soft-main" :style="obj2">
											<div v-for="(item, i) in questionBank" class="soft-sort"
												:id="item.contentType" :style="obj" ref="softSort">
												<p class="sort-tit"> <span></span> <span>{{item.contentName}}</span>
												</p>
												<div class="section">
													<div v-for="(topic, t) in item.contentDetails" class="row"
														data-required="1" id="">
														<div class="position-tit-container">
															<span>{{topic.contval}}</span> <span
																class="postion-tit">{{topic.content}}</span>
															<div class="desc-container" v-if="topic.canExplain!=false">
																<i class="explain">?</i>
																<div class="desc-main">
																	<span></span>
																	<p>{{topic.explaination}}</p>
																</div>
															</div>
														</div>
														<div class="options-container">
															<ul>
																<li :ref="answer.chooseFlag==1?'active':''"
																	:class="answer.chooseFlag==1?'active':''"
																	v-for="(answer, a) in topic.options">
																	<input type="radio"
																		:checked="answer.chooseFlag=='1'?'checked':''"
																		:id="answer.id" :name="answer.questionId"
																		:data-type="item.contentType" disabled>
																	<label :for="answer.id">{{answer.content}}</label>
																	<div class="desc-container"
																		v-if="answer.canExplain!=false">
																		<i class="explain">?</i>
																		<div class="desc-main">
																			<span></span>
																			<p>{{answer.explaination}}</p>
																		</div>
																	</div>
																</li>
															</ul>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<router-view />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>


<script>
	// 头部组件引入
	import Header from '../../common/header/header.vue';
	// 尾部组件引入
	import Footer from '../../common/footer/footer.vue';
	import '../../assets/css/common.css';
	import PaymentInformation from '../../common/getInfo/paymentInformation.vue';
	import Navigation from '../../common/getInfo/navigation.vue';
	import '../../assets/css/softIndex.css';
	import qs from 'qs'

	export default {
		data() {
			return {
				navList: ['审核状态', '基本信息', '软性指标', '资产负债表', '利润表'],
				questionBank: [],
				contentDetails: [],
				scrollTop: 0, //滚动条距离顶部的距离
				obj: {
					marginTop: 0,
					paddingTop: 40
				},
				obj2: {
					paddingTop: 0
				},
				projectName: '',
				versions: 2
			}
		},
		components: {
			Header,
			Footer,
			PaymentInformation,
			Navigation
		},
		mounted() {
			if (this.$route.query.type == 12) {
				this.projectName = this.domain.turingProject;
			}
			this.getQuestion();
		},
		methods: {
			getQuestion: function() {
				var url = this.projectName + 'auditInfoShow/getQuestion';
				this.$post(url, qs.stringify({
						stage: this.$route.query.stage,
						reportType: this.$route.query.type,
						reportId: this.$route.query.reportId ? this.$route.query.reportId : '',
						version: this.versions
					}))
					.then((res) => {
						console.log(res.data)
						this.questionBank = res.data.content;
						console.log(this.questionBank)
						// 循环条数
						var cont = 0
						for (var i = 0; i < res.data.content.length; i++) {
								for (var k = 0; k < res.data.content[i].contentDetails.length; k++) {
										this.$set(res.data.content[i].contentDetails[k], 'contval', cont+=1)
								}	
                        }
					});
			}

		}
	}
</script>

<style scoped>
	.relevantQuestionMain {
		height: 79%;
		margin-top: 3%;
	}
	
	.relevantQuestion {
		width: 84%;
		margin-top: 20px;
	}
	
	@media screen and (max-height:800px){
		.relevantQuestionMain{
			margin-top: 5%;
		}
	}
	
</style>
