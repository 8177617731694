<template>
	<div class="formMain">
		<div class="formBodyPositioning">
			<Header />
			<div class="formWidth">
				<div class="formMainModule">
					<div class="formInfomationTitle">估值报告</div>
					<PaymentInformation />
					<div class="relevantQuestionMain">
						<Navigation :nav="navList" />
						<div class="baseInfo">
							<ul class="ul_left">
								<li>
									<span>企业名称</span>
									<span id="companyName">{{basicInformation.companyName}}</span>
								</li>
								<li>
									<span>简称</span>
									<span>{{basicInformation.companyShort}}</span>
								</li>
								<li>
									<span>邮箱</span>
									<span>{{basicInformation.email}}</span>
								</li>
								<li>
									<span>成立日期</span>
									<span>{{basicInformation.establishDate}}</span>
								</li>
								<li>
									<span>法定代表人</span>
									<span>{{basicInformation.legalPeople}}</span>
								</li>
								<li>
									<span>创始人学历</span>
									<span>{{basicInformation.founderEdu}}</span>
								</li>
								<li>
									<span>注册资本</span>
									<span>{{basicInformation.regCapital?this.formatCurrency.formateNum(basicInformation.regCapital,2)+'万元':''}}</span>
								</li>
								<li>
									<span>企业所在地</span>
									<span>{{basicInformation.area}}</span>
								</li>
								<li>
									<span>详细地址</span>
									<span>{{basicInformation.address}}</span>
								</li>
								<li>
									<span>统一社会信用代码</span>
									<span>{{basicInformation.creditCode}}</span>
								</li>
								<li>
									<span>产品是否已推广到市场</span>
									<span>{{basicInformation.extendMarketFlag}}</span>
								</li>
								<li>
									<span>拟融资额度</span>
									<span>{{basicInformation.finance?this.formatCurrency.formateNum(basicInformation.finance,2)+'万元':''}}</span>
								</li>
								<li>
									<span>公司主营业务概述</span>
									<span id="bussinessDesc">{{basicInformation.bussinessDesc}}</span>
								</li>
							</ul>
							<ul class="ul_right">
								<li>
									<span>所属行业</span>
									<span id="industryName">{{basicInformation.industryName}}</span>
								</li>
								<li>
									<span>计划出让股权比例</span>
									<span>{{basicInformation.ratio?basicInformation.ratio+'%':''}}</span>
								</li>
								<li>
									<span>企业价值（自评）</span>
									<span>{{basicInformation.selfValue?this.formatCurrency.formateNum(basicInformation.selfValue,2)+'万元':''}}</span>
								</li>
								<li>
									<span>公司营业收入(预测)</span>
									<span>{{basicInformation.income?this.formatCurrency.formateNum(basicInformation.income,2)+'万元':''}}</span>
								</li>
								<li>
									<span>公司净资产(预测)</span>
									<span>{{basicInformation.netAsset?this.formatCurrency.formateNum(basicInformation.netAsset,2)+'万元':''}}</span>
								</li>
								<li>
									<span>公司净利润（预测）</span>
									<span>{{basicInformation.netProfit?this.formatCurrency.formateNum(basicInformation.netProfit,2)+'万元':''}}</span>
								</li>
								<li>
									<span>公司息税折旧摊销前利润(预测)</span>
									<span>{{basicInformation.amortizeProfit?this.formatCurrency.formateNum(basicInformation.amortizeProfit,2)+'万元':''}}</span>
								</li>
								<li>
									<span>第一股东持股比例</span>
									<span>{{basicInformation.firstHolderRatio?basicInformation.firstHolderRatio+'%':''}}</span>
								</li>
								<li>
									<span>第二股东持股比例</span>
									<span>{{basicInformation.secHolderRatio?basicInformation.secHolderRatio+'%':''}}</span>
								</li>
								<li>
									<span>第三股东持股比例</span>
									<span>{{basicInformation.thirdHolderRatio?basicInformation.thirdHolderRatio+'%':''}}</span>
								</li>
								<li>
									<span>第四股东持股比例</span>
									<span>{{basicInformation.fourHolderRatio?basicInformation.fourHolderRatio+'%':''}}</span>
								</li>
								<li>
									<span>其他股东持股比例</span>
									<span>{{basicInformation.otherHolderRatio?basicInformation.otherHolderRatio+'%':''}}</span>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	// 头部组件引入
	import Header from '../../common/header/header.vue';
	// 尾部组件引入
	import Footer from '../../common/footer/footer.vue';
	import '../../assets/css/common.css';
	import PaymentInformation from '../../common/getInfo/paymentInformation.vue';
	import Navigation from '../../common/getInfo/navigation.vue';
	import qs from 'qs'

	export default {
		data() {
			return {
				navList: ['利润表', '审核状态', '基本信息', '软性指标', '资产负债表'],
				basicInformation: {
					companyName: '',
					companyShort: '', // 公司简称
					email: '', // 邮件
					establishDate: '', //成立时间
					legalPeople: '', //法定代表人
					founderEdu: '', //创始人学历
					regCapital: '', //注册资本
					area: '', //企业所在地
					address: '', // 详细地址
					regDepart: '', // 登记机关
					creditCode: '', // 统一社会信用代码
					bussinessDesc: '', // 公司主营业务概述
					extendMarketFlag: '', //产品是否已推广到市场
					industry: '', // 一级行业标识码
					industryChild: '', //二级行业标识码
					industryName: '', //一级行业名称
					industryChildName: '', //二级行业名称
					finance: '', // 拟融资额度
					ratio: '', // 计划出让股权比例
					selfValue: '', //企业价值（自评）
					income: '', // 公司营业收入(预测)
					netAsset: '', // 公司净资产(预测)
					netProfit: '', //公司净利润（预测）
					amortizeProfit: '', // 公司息税折旧摊销前利润(预测)
					valuationPurpose: '', // 估值目的
					writerEnterpriseRelative: '', // 填写人与受评企业关系
					firstHolderRatio: '', // 第一股东持股比例
					secHolderRatio: '', // 第二股东持股比例
					thirdHolderRatio: '', // 第三股东持股比例
					fourHolderRatio: '', // 第四股东持股比例
					otherHolderRatio: '', // 其他股东持股比例
					diagnoseBaseDate: '',
					position: '',
					valuationReportId: '',
					id: ''
				},
				versions: '',
				projectName: ''
			}
		},
		components: {
			Header,
			Footer,
			PaymentInformation,
			Navigation
		},
		mounted() {
			if (this.$route.query.type == 12) {
				this.projectName = this.domain.turingProject;
			}
			this.getBaseInfo()
		},
		methods: {
			getBaseInfo: function() {
				var url = this.projectName + 'auditInfoShow/getBaseInfo';

				this.$post(url, qs.stringify({
						reportId: this.$route.query.reportId
					}))
					.then((res) => {
						if (res.data.content) {
							var content = res.data.content;
							var dataKey = Object.keys(content); //获取对象的 key
							var defaultDataKey = Object.keys(this.basicInformation)
							this.assignment(dataKey, defaultDataKey, content, 'getBaseInfo')
						}
					});
			},
			assignment: function(param, data, content, describe) {
				//param:参数key的数组   data：主要数据key的数组  content：接口返回的数据      describe：表示是填充调入的数据还是回显的数据
				data.forEach((elem, index) => {
					param.forEach((item, idx) => {
						if (elem == item) {

							if (item == 'ratio' || item == 'firstHolderRatio' ||
								item == 'secHolderRatio' || item ==
								'thirdHolderRatio' || item == 'fourHolderRatio' ||
								item == 'otherHolderRatio') {
								this.basicInformation[item] = content[item] ?
									Number(content[item]).toFixed(2) : '';
							} else if (item == 'extendMarketFlag') {
								if (content[item] == '0') {
									this.basicInformation[item] = '否';
								} else {
									this.basicInformation[item] = '是';
								}
							} else {
								this.basicInformation[item] = content[item] ? content[item] : '';
							}

						}
					});
				});
				console.log(this.basicInformation)
			}

		}
	}
</script>

<style scoped>
	.relevantQuestionMain {
		height: 79%;
		margin-top: 3%;
	}
	
	.baseInfo {
		width: 84%;
		margin: 20px auto 0;
		overflow: hidden;
	}

	.baseInfo>ul.ul_left{
		width: 50%;
		float: left;
	}
	
	.baseInfo>ul.ul_right{
		width: 45%;
		float: right;
	}

	.baseInfo>ul li {
		margin-bottom: 25px;
	}

	.baseInfo>ul li span {
		font-size: 14px;
		color: #fff;
	}

	.baseInfo>ul li>span:first-child {
		width: 40%;
		vertical-align: top;
		padding-right: 2%;
	}

	.baseInfo>ul li>span:last-child {
		width: 58%;
	}
	
	.baseInfo>ul.ul_right li>span:first-child {
		width: 54%;
	}
	.baseInfo>ul.ul_right li>span:last-child {
		width: 44%;
	}
	
	@media screen and (max-height:800px){
		.relevantQuestionMain{
			margin-top: 5%;
		}
	}
</style>
