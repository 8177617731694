export default {
	formateNum(s, n) {
		if (s == "") {
			return '0.00';
		}
		if (s == null) {
			return '0.00'
		}
		
		if (n) {
			n = n > 0 && n <= 20 ? n : 2;
			s = parseFloat((s + '').replace(/[^\d\.-]/g, '')).toFixed(n) + '';
		} else {
			s = s + "";
		}
		var l = s.split('.')[0].split('').reverse();
		var r = s.split('.')[1];

		var t = '';
		for (var i = 0; i < l.length; i++) {

			t += l[i] + ((i + 1) % 3 == 0 && (i + 1) != l.length && l[i + 1] != '.' && l[i] != '.' && l[i + 1] != "-" ?
				',' : '');
		}
		if (r == undefined) {
			return t.split('').reverse().join('');
		} else {
			return t.split('').reverse().join('') + '.' + r;
		}
	}
}
