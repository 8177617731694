<template>

	<div class="table_explain">
		<div class="grade" v-if="gradeStatus">
			<img class="battery" :src="chartSrc" alt="报告中的偿债分析之资产负债表-心流" />
			<p v-text="chartData.batteryLevel" :style="chartData.batteryLevel=='20%'?'color:#FF383D;':''">0.00%</p>
		</div>
		<h4 v-text="chartData.title"></h4>
		<div :id="id" style="width:100%;height:340px;"></div>
		<div class="explainText">
			<p v-for="(item,index) in chartDescription" :key="index">{{item}}</p>
		</div>
	</div>

</template>
<script>
	import * as echarts from 'echarts/lib/echarts.js'
	import formatCurrency from '../../assets/js/numberToCurrency'


	export default ({
		props: [
			'chartData', 'id', 'gradeStatus'
		],
		data() {
			return {
				chartName: [],
				chartValue: [],
				chartDescription: [],
				chartSrc: require("../../assets/img/report/dianchi-100.png")
			}
		},
		mounted() {
			var that = this;
			// console.log(that.chartData)
			if (that.chartData) {
				that.chartName = that.chartData.name;
				that.chartName.push("");
				that.chartValue = that.chartData.value;
				that.chartDescription = that.chartData.description.split("|");
				that.chartSrc = that.srcFormate(that.chartData.batteryLevel)
				that.initChart()
			}
		},
		methods: {
			initChart: function() {
				this.chart = echarts.init(document.getElementById(this.id));
				this.setOptions(this.id, this.chartName, this.chartValue)
			},
			setOptions: function(id, dataxAxis, datayAxis) {
				var companyValue = datayAxis[0];
				var averageIndex;
				var datayAxis_ = [];
				var markline1 = [];

				datayAxis.push('')
				for (var i = 0; i < dataxAxis.length; i++) {
					if (dataxAxis[i] == "行业均值") {
						averageIndex = i
					};
					datayAxis_.push({
						value: datayAxis[i]
					})
					markline1.push(datayAxis[0])
				}
				// console.log(markline1)
				var markLineValue = companyValue - datayAxis[averageIndex],
					labelObj = {},
					normalObj = {};
				switch (id) {
					case "totalAssetsReturnOn":
						var titleText = "总资产收益率";
						var yAxisName = "%";
						break;
					case "saleRatio":
						var titleText = "销售费用率";
						var yAxisName = "%";
						break;
					case "manageRatio":
						var titleText = "管理费用率";
						var yAxisName = "%";
						break;
					case "researchRatio":
						var titleText = "研发费用率";
						var yAxisName = "%";
						break;
					case "grossProfitRatio":
						var titleText = "毛利率";
						var yAxisName = "%";
						break;
					case "netProfiltRatio":
						var titleText = "净利润率";
						var yAxisName = "%";
						break;
					case "mainIncomeRatio":
						var titleText = "主营业务收入增长率";
						var yAxisName = "%";
						break;
					case "totalProfitRatio":
						var titleText = "利润总额增长率";
						var yAxisName = "%";
						break;
					case "netAssetRatio":
						var titleText = "净资产收益率";
						var yAxisName = "%";
						break;
					case "liabilitiesRatio":
						var titleText = "资产负债率";
						var yAxisName = "%";
						break;
					case "flowRation":
						var titleText = "流动比率";
						var yAxisName = "";
						break;
					case "quickRatio":
						var titleText = "速动比率";
						var yAxisName = "";
						break;
					case "ebitMultiple":
						var titleText = "EBIT利息保障倍数";
						var yAxisName = "";
						break;


					case "receivablesTurnoverRatio":
						var titleText = "应收账款周转率";
						var yAxisName = "";
						break;
					case "inventoryTurnoverRatio":
						var titleText = "存货周转率";
						var yAxisName = "";
						break;
				}

				var xAxis = [],
					yAxis = [],
					position;
				var xObj = {},
					yObj = {
						axisLabel: {
							textStyle: {
								fontSize: 18,
								color: "#282828",
							},
							formatter: function(value, index) {
								return value + yAxisName;
							},
							margin: 20,
						},
						axisLine: {
							show: false
						}
					},
					axisTick = {
						alignWithLabel: true
					},
					data = [],
					axisOne = {},
					axisTwo = {},
					coordOne = [],
					coordTwo = [],
					axisLabel = {
						interval: 0,
						margin: 35,
						formatter(params) {
							var newParamsName = ""; // 最终拼接成的字符串
							var paramsNameNumber = params.length; // 实际标签的个数
							var provideNumber = 6; // 每行能显示的字的个数
							var rowNumber = Math.ceil(paramsNameNumber / provideNumber); // 换行的话，需要显示几行，向上取整
							/**
							 * 判断标签的个数是否大于规定的个数， 如果大于，则进行换行处理 如果不大于，即等于或小于，就返回原标签
							 */
							// 条件等同于rowNumber>1
							if (paramsNameNumber > provideNumber) {
								/** 循环每一行,p表示行 */
								for (var p = 0; p < rowNumber; p++) {
									var tempStr = ""; // 表示每一次截取的字符串
									var start = p * provideNumber; // 开始截取的位置
									var end = start + provideNumber; // 结束截取的位置
									// 此处特殊处理最后一行的索引值
									if (p == rowNumber - 1) {
										// 最后一次不换行
										// tempStr = params.substring(start, paramsNameNumber);
									} else {
										// 每一次拼接字符串并换行
										tempStr = params.substring(start, end) + '...';
									}
									newParamsName += tempStr; // 最终拼成的字符串
								}

							} else {
								// 将旧标签的值赋给新标签
								newParamsName = params;
							}
							//将最终的字符串返回
							return newParamsName
						}
					},
					textStyle = {
						fontSize: 16,
						color: "#282828",
						lineHeight: 24
					};
				xObj.type = "category";
				xObj.data = dataxAxis;
				xObj.axisTick = axisTick;
				xObj.axisLabel = axisLabel;
				xObj.axisLine = {
					show: true,
					lineStyle: {
						color: "#282828"
					}
				}
				axisTick.show = false;
				axisLabel.textStyle = textStyle;
				xAxis.push(xObj);

				yObj.type = "value";
				yObj.nameTextStyle = {
					color: "#282828",
					fontSize: 16
				}
				yObj.splitLine = {
					lineStyle: {
						color: '#F3F2F2'
					}
				};
				yAxis.push(yObj);

				position = "bottom";
				axisOne.yAxis = datayAxis[0];
				axisOne.symbolSize = [0, 0];
				axisTwo.yAxis = datayAxis[averageIndex];
				axisTwo.symbolSize = [0, 0];
				coordOne = [6, companyValue];
				coordTwo = [6, datayAxis[averageIndex]];
				normalObj.show = true;
				normalObj.position = position;
				normalObj.offset = [0, 8]
				var textObj = {
					color: "#282828",
					fontSize: 16
				};
				normalObj.textStyle = textObj;
				normalObj.formatter = '{c}';
				labelObj.normal = normalObj;
				var option = {
					title: {
						show: false,
						text: titleText,
						textStyle: {
							color: '#282828',
							align: 'center',
							fontSize: 16
						},
						left: 'center',
						top: 'top'
					},
					tooltip: {
						trigger: 'axis',
						axisPointer: { // 坐标轴指示器，坐标轴触发有效
							type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
						},
						formatter(params) {
							// 只展示柱子对应的内容，把顶部底部的 tooltip 过滤掉
							return params.reduce((pre, i) => {
								if (i.componentSubType === "line") {
									i.marker = i.marker.replace(/\[object Object\]/, i.color);
									i.value =
										`<span style="flex: 1; text-align: right; margin-left: 16px;">${i.value}</span>`;
									const current =
										`<div style="display: flex; align-items: center; height: 26px;">${i.marker}${i.axisValue} ${i.value}</div>`;
									return `${pre}${current}`;
								}
								if (i.componentSubType === 'bar') {
									i.marker = i.marker.replace(/\[object Object\]/, i.color
										.colorStops[1].color);
									i.value =
										`<span style="flex: 1; text-align: right; margin-left: 16px;">${i.value}</span>`;
									const current =
										`<div style="display: flex; align-items: center; height: 26px;">${i.marker}${i.axisValue} ${i.value}</div>`;
									return `${pre}${current}`;
								}
								return pre;
							}, '');
						}
					},
					grid: { // 控制图的大小，调整下面这些值就可以，
						left: '2%',
						right: '0',
						bottom: '10%',
						top: '12%',
						containLabel: true
					},
					xAxis: xAxis,
					yAxis: yAxis,
					series: [{
							data: datayAxis_,
							type: 'bar',
							z: 12,
							barMaxWidth: 68,
							itemStyle: {
								color: function(params) {
									var colorList = ['#94B5FF', '#E4F5FF', '#DBC9FF', '#EEB9E5',
										'#ACF5F9', '#CAFFED', 'rgba(0,0,0,0)'
									];
									var colorList_ = ['#0149F2', '#019CF2', '#6A1AFF', '#EB7DDB',
										'#43DBE4', '#69E0B5', 'rgba(0,0,0,0)'
									];
									return new echarts.graphic.LinearGradient(0, 0, 1, 0, [{
										offset: 0,
										color: colorList[params.dataIndex]
									}, {
										offset: 1,
										color: colorList_[params.dataIndex]
									}]);
								}
							},
							label: labelObj,
							markLine: {
								lineStyle: {
									color: '#282828',
								},
								label: {
									show: false,
								},
								data: [
									[{
											coord: coordOne,
											lineStyle: {
												color: '#282828',
												type: 'solid'
											},
											symbolSize: [0, 0],
										},
										{
											coord: coordTwo,
											lineStyle: {
												color: '#282828',
												type: 'solid'
											},
											// 箭头标识
											label: {
												show: true,
												position: 'middle',
												formatter: function(params) {
													var value = markLineValue.toFixed(2);
													return value;
												},
												textStyle: {
													fontSize: 16,
													color: "#282828"
												}
											}
										}
									]

								]
							}

						}, {
							type: 'pictorialBar',
							symbolSize: [68, 14],
							// 这个属性很重要，直接决定了顶部跟柱子是否契合
							// symbolOffset: [0, -10],
							z: 13,
							itemStyle: {
								normal: {
									label: {
										show: false,
										position: 'top', //在上方显示
										textStyle: {
											color: '#282828'
										},
										formatter: function(p) {
											return formatCurrency.formateNum(p.value.toString(), 2);
										}
									}
								}
							},
							data: this.pictorialBar1(datayAxis),
						},
						{
							type: 'line',
							z: 20,
							yAxisIndex: 0, //选择纵坐标的第几个
							// data:markline1
							markLine: {
								symbolSize: [0, 0],
								silent: true,
								lineStyle: {
									color: '#282828',
								},
								label: {
									show: false
								},
								data: [
									axisOne,
									axisTwo,
								]
							}
						},

					]
				};
				var myChart = echarts.init(document.getElementById(id));
				// 使用刚指定的配置项和数据显示图表。
				myChart.setOption(option);
			},
			// 电池图片格式化
			srcFormate: function(val) {
				if (val == "20%") {
					return require("../../assets/img/report/dianchi-20.png")
				} else if (val == "40%") {
					return require("../../assets/img/report/dianchi-40.png")
				} else if (val == "60%") {
					return require("../../assets/img/report/dianchi-60.png")
				} else if (val == "80%") {
					return require("../../assets/img/report/dianchi-80.png")
				} else if (val == "100%") {
					return require("../../assets/img/report/dianchi-100.png")
				}
			},
			pictorialBar1: function(data) {
				var arr = [];
				var symbol = ['image://' + require('../../assets/img/report/ellipse1.png'), 'image://' + require(
					'../../assets/img/report/ellipse3.png'), 'image://' + require(
					'../../assets/img/report/ellipse4.png'), 'image://' + require(
					'../../assets/img/report/ellipse7.png'), 'image://' + require(
					'../../assets/img/report/ellipse.png'), 'image://' + require(
					'../../assets/img/report/ellipse5.png'), '']
				data.forEach(function(ele, i) {
					if (ele < 0) {
						arr[i] = {
							value: ele,
							symbolOffset: [0, 7],
							symbolPosition: 'end',
							symbol: symbol[i]
						}
					} else {
						arr[i] = {
							value: ele,
							symbolOffset: [0, -7],
							symbolPosition: 'end',
							symbol: symbol[i]
						}
					}
				});

				return arr
			}

		}
	})
</script>
<style scoped>
	#totalAssetsReturnOn,
	#saleRatio,
	#manageRatio,
	#researchRatio,
	#grossProfitRatio,
	#netProfiltRatio,
	#mainIncomeRatio,
	#totalProfitRatio,
	#netAssetRatio,
	#flowRation,
	#quickRatio,
	#ebitMultiple,
	#receivablesTurnoverRatio,
	#inventoryTurnoverRatio,
	#totalAssetsTurnoverRatio,
	#liabilitiesRatio {
		height: 340px;
		width: 100%;
		padding: 0 50px;
	}

	.grade img.battery {
		margin-right: 10px;
		height: 48px;
		width: 100px;
		float: left;
	}

	.grade p {
		font-size: 26px;
		font-weight: bold;
		color: #282828;
		line-height: 48px;
		float: left;
	}

	.grade {
		position: absolute;
		top: 0;
		right: 0;
		width: 184px;
	}

	.table_explain {
		position: relative;
		overflow: hidden;
		margin-top: 51px;
	}

	.table_explain .explainText p {
		font-size: 20px;
		font-weight: 400;
		color: #333333;
		line-height: 34px;
		text-indent: 2em;
		text-align: justify;
	}

	.table_explain h4 {
		font-size: 26px;
		font-weight: bold;
		color: #282828;
		margin: 16px 0 30px 0;
	}
</style>
