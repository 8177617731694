<template>
    <div>
        <!-- 隐私政策 -->
        <div class="agreement confidentiality">
                <div class="text_content">
                    <h3>隐私政策</h3>
                    <div class="content agreementContent">
                        <h4>本协议版本发布/更新日期为：2021年1月1日</h4>
                        <h4 style="text-align: center;">心流隐私政策</h4>
                        <h4>一、我们如何收集和使用您的个人/企业信息</h4>
                        <p>个人信息是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息，本隐私政策中涉及的个人信息包括个人电话号码、电子邮箱等。</p>
                        <p>企业信息指和该企业的注册和经营有关的信息，本隐私政策中涉及的企业信息包括企业基本信息、软性指标、财务报表、资产权属情况、财务清单、法务清单等使用智能股权价值服务系统（SEVS）产品所需要的各种信息。</p>
                        <p>我们会出于以下目的，收集和使用您的个人信息及您填写的企业信息：</p>
                        <h4>（一）为您提供智能股权价值服务所必须的功能</h4>
                        <p>1、注册成为用户</p>
                        <p>为完成创建账号，您需要提供个人验证身份的手机号码、电子邮箱地址。</p>
                        <p>2、使用线上产品</p>
                        <p>根据您企业自身的需求选择对应的线上产品与/或服务来填写所需服务应提交的企业信息。</p>
                        <p>3、支付功能</p>
                        <p>在您提交后，您可以选择第三方支付机构（包括微信支付及支付宝支付通道）所提供的支付服务。支付功能本身并不收集您的个人信息，但我们需要将您的交易金额信息与这些支付机构共享以实现其确认您的支付指令并完成支付。</p>
                        <p>4、交付产品与/或服务功能</p>
                        <p>在您在线完成支付后，我们通过后台审核，审核无误后一键生成对应的报告，您可通过登录注册账号浏览和下载该报告。</p>
                        <p>5、客服与售后功能</p>
                        <p>我们的估值客服和售后客服会使用您的个人信息和您填写的企业信息，对线上产品与/或服务填写的信息进行核实、辅导填写及后续的跟踪服务</p>
                        <h4>（二）开展内部数据分析和行业研究，改善我们的产品与/或服务</h4>
                        <p>我们通过对智能股权价值服务系统（SEVS）产品收集的企业数据进行行业分类研究，从而优化和改善我们的系统，以便为您提供更好的线上服务。</p>
                        <h4>二、我们如何使用Cookie和同类技术</h4>
                        <p>我们使用 Cookie 和类似技术来存储和遵守您的偏好和设置、使您能够登录。</p>
                        <p>您可根据自己的偏好管理或删除 Cookie。除了清除计算机上保存的所有 Cookie，大部分网络浏览器都设有阻止 Cookie 的功能。但如果您这么做，则需要在每一次访问我们的网站时更改用户设置。如需详细了解如何更改浏览器设置，请访问您使用的浏览器的相关设置页面。</p>
                        <h4>三、我们如何共享、转让、公开披露您的个人信息</h4>
                        <h5>（一）共享</h5>
                        <p>我们不会与心流以外的任何公司、组织和个人共享您的个人/企业信息，但以下情况除外：</p>
                        <p>1、事先获得您明确的同意或授权；</p>
                        <p>2、根据适用的法律法规、法律程序的要求、强制性的行政或司法要求所必须的情况下进行提供；</p>
                        <p>3、在法律法规允许的范围内，为维护心流、心流的关联方或合作伙伴、您或其他心流用户或社会公众利益、财产或安全免遭损害而有必要提供；</p>
                        <p>4、 只有共享您的个人/企业信息，才能实现我们的产品与/或服务的核心功能或提供您需要的服务；</p>
                        <p>5、应您需求为您处理您与他人的纠纷或争议。</p>
                        <h5>（二）转让</h5>
                        <p>我们不会将您的个人/企业信息转让给任何公司、组织和个人，但以下情况除外：</p>
                        <p>1、事先获得您明确的同意或授权；</p>
                        <p>2、根据适用的法律法规、法律程序的要求、强制性的行政或司法要求所必须的情况进行提供；</p>
                        <p>3、在涉及合并、收购、资产转让或类似的交易时，如涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织继续受本隐私政策的约束，否则，我们将要求该公司、组织重新征求您的授权同意。</p>
                        <h5>（三）公开披露</h5>
                        <p>我们仅会在以下情况下，公开披露您的个人/企业信息：</p>
                        <p>1、事先获得您明确的同意或授权；</p>
                        <p>2、根据法律、法规的要求、强制性的行政执法或司法要求所必须提供您个人信息的情况下，我们可能会依据所要求的个人信息类型和披露方式公开披露您的个人信息。在符合法律法规的前提下，当我们收到上述披露信息的请求时，我们会要求必须出具与之相应的法律文件，如传票或调查函。我们坚信，对于要求我们提供的信息，应该在法律允许的范围内尽可能保持透明。我们对所有的请求都进行了慎重的审查，以确保其具备合法依据，且仅限于执法部门因特定调查目的且有合法权利获取的数据。</p>
                        <h4>四、我们如何保护和保存您的个人/企业信息</h4>
                        <p>（一）我们已使用符合业界标准的安全防护措施保护您提供的个人/企业信息，防止数据遭到未经授权访问、公开披露、使用、修改、损坏或丢失。我们会采取一切合理可行的措施，保护您的个人/企业信息。例如，在您的浏览器与“服务器”之间交互数据时受SSL加密保护；我们同时对我们网站提供https安全浏览方式；我们会使用加密技术确保数据的保密性；我们会使用受信赖的保护机制防止数据遭到恶意攻击；我们会部署访问控制机制，确保只有授权人员才可访问个人/企业信息；以及我们会举办安全和隐私保护培训，加强员工对于保护个人/企业信息重要性的认识。</p>
                        <p>（二）我们会采取一切合理可行的措施，确保未收集无关的个人/企业信息。我们只会在达成本隐私政策所述目的所需的。</p>
                        <p>（三）互联网并非绝对安全的环境，而且电子邮件、即时通讯、社交软件等与其他用户的交流方式无法确定是否完全加密，我们建议您使用此类工具时请使用复杂密码，并注意保护您的个人信息安全。</p>
                        <p>（四）互联网环境并非百分之百安全，我们将尽力确保或担保您发送给我们的任何信息的安全性。如果我们的物理、技术或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改或毁坏，导致您的合法权益受损，我们将承担相应的法律责任。</p>
                        <p>（五）一旦发生个人信息安全事件，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们同时将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，当难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。</p>
                        <h4>五、您的权利</h4>
                        <p>按照中国相关的法律、法规、标准，以及其他国家、地区的通行做法，我们保障您对自己的个人/企业信息行使以下权利：</p>
                        <h5>（一）访问您的个人/企业信息</h5>
                        <p>您有权通过您的个人注册账号访问您前期填报的企业信息，法律法规规定的例外情况除外。</p>
                        <h5>（二）更正您的个人/企业信息</h5>
                        <p>当您发现您填报的企业信息错误时，您有权在我的报告页面选择相应的产品与/或服务项目自行修改，或当您提交使用的产品与/或服务信息被审核退回时，您可参考退回罗列的修改建议进行更正后再次提交。</p>
                        <h5>（三）删除您的个人/企业信息</h5>
                        <p>您在我的报告页面中可以直接删除未提交但已使用的产品与/或服务项目的企业信息。</p>
                        <p>在以下情形中，您可以向我们提出删除个人/企业信息的请求：</p>
                        <p>1、如果我们处理个人信息/企业信息的行为违反法律法规；</p>
                        <p>2、如果我们收集、使用您的个人/企业信息，却未征得您的同意；</p>
                        <p>3、如果我们处理个人信息/企业信息的行为违反了与您的约定；</p>
                        <p>4、如果我们终止服务及运营。</p>
                        <h5>（四）响应您的请求</h5>
                        <p>如果您无法通过上述方式访问、更正或删除您的企业信息，或您认为心流存在任何违反法律法规或与您关于个人/企业信息的收集或使用的约定，您均可以通过本隐私政策的联系方式与我们取得联系。为了保障安全，我们可能需要您提供书面请求，或提供您或填报企业的身份证明文件，我们将在收到您反馈并验证您身份后的15天内答复您的请求。对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情况收取一定的成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际的请求，我们可能会予以拒绝。</p>
                        <p>在以下情形下，按照法律法规要求，我们将无法响应您的请求：</p>
                        <p>1、与个人/企业信息控制者履行法律法规规定的义务相关的；</p>
                        <p>2、与刑事侦查、起诉、审批和执行判决等有关的；</p>
                        <p>3、有充分证据表明您存在主观恶意或滥用权力的；</p>
                        <p>4、出于维护个人/企业信息主体或财产等重大合法权益但又很难得到本人同意的；</p>
                        <p>5、响应您的请求将导致您或其他人、组织的合法权益受到严重损害的；</p>
                        <p>6、涉及商业机密的。</p>
                        <h4>六、本隐私政策如何更新</h4>
                        <p>我们可能适时会对本隐私政策进行调整或变更，本隐私政策的任何更新将以标注更新时间的方式公布在我们网站上，除法律法规或监管规定外，经调整或变更的内容一经通知或公布后生效。如您在隐私政策调整或变更后继续使用我们提供的任何产品与/或服务，我们相信这代表您已充分阅读、理解并接受修改后的隐私政策并受其约束。</p>
                        <h4>七、如何联系我们</h4>
                        <p>如果您对本隐私政策有任何疑问、意见或建议，通过以下方式与我们联系：</p>
                        <p>电话：010-84446599</p>
                        <p>一般情况下，我们将在15天内回复。如果您对我们的回复不满意，特别是我们的个人信息处理行为损害了您的合法权益，您还可以向网信、电信、公安及工商等监管部门进行投诉或举报。</p>
                    </div>
                </div>
            </div>
    </div>
</template>
<script>
    export default {
        name:"confidentiality",
        data() {
            return {

            }
        }
    }
</script>
<style scoped>
    .content {
        width: 100%;
    }
    .agreement {
        padding: 0 20px;
    }
    .agreement h3 {
        text-align: center;
        font-size:22px;
        line-height:48px;
        color:#000;
        font-weight: bold;
    }
    .agreement p {
        font-size: 14px;
        color:#000;
        line-height:28px;
        text-align: justify;
        text-indent:28px;
    }
    .agreement h5 {
        font-size: 14px;
        color:#000;
        line-height:28px;
        text-align: justify;
    }
    .agreement h4 {
        text-align: left;
        font-size:18px;
        line-height:40px;
        color:#000;
        font-weight: bold;
    }
</style>