<template>
	<div :id="id"></div>
</template>
<script>
	import * as echarts from 'echarts/lib/echarts.js'

	export default ({
		props: [
			'chartData', 'id'
		],
		data() {
			return {
				radarStyle: {
					fontSize: 18,
					radius: "60%",
					lineHeight: 34,
					nameGap: 10
				}
			}
		},
		mounted() {

			if (this._isMobile()) {
				// 移动端
				this.radarStyle = {
					fontSize: 12,
					radius: "50%",
					lineHeight: 24,
					nameGap: 4
				}
				this.initChart();
			} else {
				// pc端
				this.radarStyle = {
					fontSize: 22,
					radius: "60%",
					lineHeight: 34,
					nameGap: 10
				}
				this.initChart();
			}
		},
		methods: {
			//App.vue
			_isMobile: function() {
				let flag = navigator.userAgent.match(
					/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
					)
				return flag;
			},
			initChart: function() {
				this.chart = echarts.init(document.getElementById(this.id));
				this.setOptions(this.id, this.chartData, this.radarStyle)
			},
			setOptions: function(id, data, style) {
				var indicatorArr = [],
					valueArr = [];
				for (var i = 0; i < data.length; i++) {
					var obj = {};
					obj.max = 100;
					obj.name = data[i].name;
					indicatorArr.push(obj);
					valueArr.push(data[i].value);
				}
				if (id == "flowDiagnose_BaseInfo") {
					var randerName = "心流图谱";
				} else {
					var randerName = "财务分析"
				}
				var drawOption = {
					tooltip: {
						trigger: 'axis',
					},
					radar: {
						name: {
							textStyle: {
								color: '#000',
								fontSize: style.fontSize,
								fontWeight: 'bold',
								lineHeight: style.lineHeight
							},
							formatter: function(name, value) {
								let nameResult = "";
								for (var i = 0; i < data.length; i++) {
									if (name == data[i].name) {

										if (id != "flowDiagnose_BaseInfo" && (name.indexOf("精细化") != -1 ||
												name.indexOf("新颖化") != -1)) {
											var twowordage = data[i].name.substring(0, 6);
											var twonumber = data[i].name.substring(6, data[i].name.length);
											nameResult = twowordage + '\n' + twonumber + '\n' + data[i]
												.value
										} else {
											nameResult = data[i].name + data[i].value + '分';
										}
									}
								}
								return nameResult;
							}
						},
						center: ['50%', '50%'],
						radius: style.radius,
						nameGap: style.nameGap, // 名称和轴的距离
						fontSize: 16,
						shape: 'polygon', // 雷达图绘制类型:circle,polygon
						axisLine: { // 坐标轴线相关位置
							show: true, // 坐标轴线的显示与否，默认显示，属性show控制显示与否
							symbol: 'none', // 轴线两边的箭头 ["none","none"]
							fontSize: 16,
							color: "#000",
							lineStyle: {
								color: '#d6d6d6',
								// width: 1,
								// type: 'solid', //可选值：solid，dashed,dotted
							}
						},
						// axisLabel:{
						//     fontSize: 28,

						// },
						indicator: indicatorArr,
						splitArea: {
							show: true,
							areaStyle: {
								color: ["#604AF1", "#4307FF", "#6433FF", "#8B68FF", "#B9A3FF", ]
							}
						},
						splitLine: {
							lineStyle: {
								color: "#2200FF"
							}
						},
					},
					series: [{
						type: 'radar',
						showSymbol:false,
						tooltip: {
							trigger: 'item'
						},
						itemStyle: { //  折线拐点标志的样式
							opacity:0
						},
						data: [{
							name: randerName,
							value: valueArr, // 单个数据的值
							label: {
								show: false, // 标签是否显示
								//                  formatter: '{c}',
								formatter: function(p) {
									return formateNum(p.value.toString());
								},
								color: '#000',
								fontSize: 16,
							},
							lineStyle: { // 单项线条样式
								color: '#FFE21E',
								type: 'solid',
							},
							areaStyle: { // 单项区域填充样式
								color: '#FFE21E',
							}
						}],
					}]
				}
				this.chart.setOption(drawOption);
			}
		}
	})
</script>
<style scoped>
	#financialAnalysisPic,
	#flowDiagnosePic {
		width: 560px;
		height: 400px;
		margin: 60px auto;
	}

	@media screen and (max-width: 765px) {

		#financialAnalysisPic,
		#flowDiagnosePic {
			width: 100%;
			height: auto;
			margin: 60px auto;
		}

		.financialAnalysis,
		.flowDiagnose {
			float: inherit;
			margin: 0 0 20px 0;
		}
	}
</style>
