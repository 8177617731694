import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		channel: '',
		reportId:'',
		currentPageModify:false,// 当前页面是否已经修改，默认未修改
		currentPageSave:false,   // 当前页面是否已经保存，默认未保存
		traceYear:'',  //价值追踪报告选择xxx年进行填写信息
		yearCurrent:0  //查看信息中价值追踪的选择的当前年份
	},
	mutations: {
		changeChannel(state,data) {
			state.channel=data;
		},
		pageModify(state,data){
			state.currentPageModify=data;
		},
		changeTraceYear(state,data){
			state.traceYear=data;
		},
		changeCurrentYearIdx(state,data){
			state.yearCurrent=data;
		}

	},
	actions: {

	}
})
