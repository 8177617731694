<template>
	<div>
		<div class="paymentInformation">
			<ul>
				<li>
					<img src="../../assets/img/getInfo/enterprise.png" alt="公司名称-心流">
					<span>{{info.companyName}}</span>
				</li>
				<li>
					<img src="../../assets/img/getInfo/time.png" alt="公司名称-心流">
					<span>支付日期-{{info.payTime}}</span>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
	import qs from 'qs'

	export default {
		data() {
			return {
				reportId: this.$route.query.reportId ? this.$route.query.reportId : "",
				info: {}
			}
		},
		components: {

		},
		mounted() {
			if (this.reportId) {
				this.initPage();
			}
		},
		methods: {
			initPage: function() {
				var that = this;
				var queryObj = {
					reportId: that.reportId //6f219310ce054632a8bdb6048c60d774  6d6f50d4f7c04b9bb35b2d83621d2edf
				}
				that.$post(this.domain.ajaxUrl + "pay/getPayBillInfo", qs.stringify(queryObj)).then(res => {
					that.info = res.data.content;
				})

			}
		}
	}
</script>

<style scoped>
	.paymentInformation {
		width: 100%;
		position: absolute;
		top: 16px;
	}

	.paymentInformation>ul {
		float: right;
		margin-right: 35px;
		/* background: #0229b4; */
		border-radius: 10px;
	}
	
	.paymentInformation>ul li{
		width: 180px;
	}

	.paymentInformation ul li img {
		margin-right: 10px;
		vertical-align: middle;
	}

	.paymentInformation ul li span {
		width: 140px;
		font-size: 14px;
		color: #FFFFFF;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.paymentInformation ul li:first-child span {
		font-weight: bold;
		font-size: 16px;
	}

	.paymentInformation ul li:first-child {
		margin-bottom: 10px;
	}
</style>
